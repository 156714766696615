import React from "react";
import strings from "../locale/strings"

class Security extends React.Component {
  render() {
    return (
      <div className="uk-section uk-text-center">
        <div className="uk-container uk-container-small">
          <div data-uk-scrollspy="cls: uk-animation-slide-bottom-medium; repeat: true">
            <h2 className="uk-h1 uk-margin-bottom">{strings.serurity}</h2>
            <p className="uk-text-lead uk-text-center">
              {strings.securityDescription}
            </p>
            <a
              className="uk-button uk-button-primary uk-button-large uk-margin-medium-top"
              href="/page/guide"
            >
              {strings.guideButton}
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Security;
