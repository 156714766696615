import React from "react";
import strings from "../locale/strings"
import UIkit from 'uikit';
// import { NavLink } from "react-router-dom";

class SearchForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      results: []
    }  
    this.typingSearch = this.typingSearch.bind(this)

  }

  typingSearch(e) {
    e.preventDefault();

    let list = strings.list
    let searchResults = []

    for (var i=0 ; i < list.length ; i++)
    {
        // if (list[i]["keyword"] === e.target.value.toLowerCase()) {
        //   searchResults.push(list[i]);
        // }
        if(list[i]["keyword"].indexOf(e.target.value.toLowerCase()) > -1 && e.target.value.toLowerCase() !== "") {
          searchResults.push(list[i]);
        }
    }

    this.setState({results: searchResults})

    if(searchResults.length !== 0) {
      UIkit.dropdown('#seach-dropdown').show();      
    } else {
      UIkit.dropdown('#seach-dropdown').hide(true);
    }
  }

  renderResults() {
    let { results } = this.state
    console.log(results)
    return(
      results.map((result, i) => {
        return (
          <li style={{fontSize: '16px', padding: '10px 0'}}>
           {/* <NavLink to={result["link"]}>{result["name"]}</NavLink> */}
           <a href={result["link"]}>{result["name"]}</a>
          </li>
        ) 
      })
    )
  }

  render() {
    return (
      <form
        className="uk-search uk-search-default uk-width-1-1"
        name="search-hero"
      >
        {/* <span className="uk-search-icon-flip" data-uk-search-icon />
        <input
          id="search-hero"
          className="uk-search-input uk-box-shadow-large"
          type="search"
          placeholder={strings.searchButton}
          autoComplete="off"
          data-minchars={1}
          data-maxitems={30}
          onChange={this.typingSearch}
        /> */}

        <div className="boundary-align">
          <span className="uk-search-icon-flip" data-uk-search-icon />
          <input
            id="search-hero"
            className="uk-search-input uk-box-shadow-large"
            type="search"
            placeholder={strings.searchButton}
            autoComplete="off"
            data-minchars={1}
            data-maxitems={30}
            onChange={this.typingSearch}
          />        
          <button className="uk-button uk-button-default uk-float-left" type="button" style={{display: 'none'}}></button>
          <div id="seach-dropdown" uk-dropdown="pos: bottom-justify; boundary: .boundary-align; boundary-align: true">
              <ul className="uk-nav uk-dropdown-nav">
                {this.renderResults()}
              </ul>
          </div>
        </div>        

      </form>
      
    );
  }
}

export default SearchForm
