import React from "react";
import Logo from "./Logo";
import strings from "../locale/strings"
import AppProvider from "../misc/providers"
import { getLocale } from '../misc/localeManager'

class Header extends React.Component {
  static contextType = AppProvider;

  constructor(props) {
    super(props)
    this.state = {
      locale: 'MN',
      nextLocale: 'GB'
    }
  }

  componentDidMount() {
    const { locale, nextLocale } = getLocale()
    this.setState({
      locale, nextLocale
    })
  }

  handleChangeLocale = () => {
    this.context.toggleLocale()
    const { locale, nextLocale } = getLocale()
    this.setState({
      locale, nextLocale
    })
  }

  render() {
    return (
      <header>
        <div data-uk-sticky="animation: uk-animation-slide-top; sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky; cls-inactive: uk-navbar-transparent; top: 200">
          <nav className="uk-navbar-container">
            <div className="uk-container">
              <div data-uk-navbar>
                <div className="uk-navbar-left">
                  <Logo></Logo>

                  <div id="canvas-menu" uk-offcanvas="overlay: true">
                    <div className="uk-offcanvas-bar">
                      <div className="uk-section">
                        <div className="uk-container uk-container-small">
                          <h3 className="uk-h1 uk-text-center">{strings.menu}</h3>
                          <ul
                            className="uk-margin-medium-top"
                            data-uk-accordion="multiple: true"
                          >
                            <li>
                              <a
                                className="uk-accordion-title uk-box-shadow-hover-small"
                                href="/"
                              >
                                {strings.aboutUs}
                              </a>
                              <div className="article-content uk-accordion-content link-primary">
                                <p>
                                  <ul className="uk-nav uk-navbar-dropdown-nav">
                                    <li>
                                      <a style={{ fontSize: '17px', padding: '12px 0' }} href="/page/aboutUs">{strings.aboutUs}</a>
                                    </li>
                                    {/* <li>
                                        <a style={{fontSize: '17px', padding: '12px 0'}} href="/page/headMessage">{strings.headMessage}</a>
                                      </li> */}
                                    <li>
                                      <a style={{ fontSize: '17px', padding: '12px 0' }} href="/page/office">{strings.office}</a>
                                    </li>
                                    {/* <li>
                                      <a style={{ fontSize: '17px', padding: '12px 0' }} href="/content/annualReport">{strings.annualReport}</a>
                                    </li> */}
                                  </ul>
                                </p>
                              </div>
                            </li>
                            <li>
                              <a
                                className="uk-accordion-title uk-box-shadow-hover-small"
                                href="/"
                              >
                                {strings.onlineApplication}
                              </a>
                              <div className="article-content uk-accordion-content link-primary">
                                <p>
                                  <ul className="uk-nav uk-navbar-dropdown-nav">
                                    <li>
                                      <a style={{ fontSize: '17px', padding: '12px 0' }} href="/page/help">{strings.help}</a>
                                    </li>
                                    <li>
                                      <a style={{ fontSize: '17px', padding: '12px 0' }} href="/page/greivanceChecklist">{strings.greivanceChecklist}</a>
                                    </li>
                                    <li>
                                      <a style={{ fontSize: '17px', padding: '12px 0' }} href="/page/howDoWeDeal">{strings.howDoWeDeal}</a>
                                    </li>
                                    <li>
                                      <a style={{ fontSize: '17px', padding: '12px 0' }} href="/page/faq">{strings.faq}</a>
                                    </li>
                                    <li>
                                      <a style={{ fontSize: '17px', padding: '12px 0' }} href="/sirm/login">{strings.makeGreviance}<span style={{ color: 'green' }} data-uk-icon="icon: sign-in; ratio: 1.2"></span></a>
                                    </li>
                                  </ul>
                                </p>
                              </div>
                            </li>
                            <li>
                              <a
                                className="uk-accordion-title uk-box-shadow-hover-small"
                                href="/"
                              >
                                {strings.resolvedCases}
                              </a>
                              <div className="article-content uk-accordion-content link-primary">
                                <p>
                                  <ul className="uk-nav uk-navbar-dropdown-nav">
                                    <li>
                                      <a style={{ fontSize: '17px', padding: '12px 0' }} href="/knowledgeBase">{strings.knowledgeBase}</a>
                                    </li>
                                    <li>
                                      <a style={{ fontSize: '17px', padding: '12px 0' }} href="/content/recommendation">{strings.governmentLegislation}</a>
                                    </li>
                                  </ul>
                                </p>
                              </div>
                            </li>
                            <li>
                              <a
                                className="uk-accordion-title uk-box-shadow-hover-small"
                                href="/"
                              >
                                {strings.information}
                              </a>
                              <div className="article-content uk-accordion-content link-primary">
                                <p>
                                  <ul className="uk-nav uk-navbar-dropdown-nav">
                                    <li>
                                      <a style={{ fontSize: '17px', padding: '12px 0' }} href="/page/guide">{strings.guide}</a>
                                    </li>
                                    <li>
                                      <a style={{ fontSize: '17px', padding: '12px 0' }} href="/content/news">{strings.news}</a>
                                    </li>
                                  </ul>
                                </p>
                              </div>
                            </li>
                            {/* <li>
                              <a
                                className="uk-accordion-title uk-box-shadow-hover-small"
                                href="/"
                              >
                                {strings.PPCC}
                              </a>
                              <div className="article-content uk-accordion-content link-primary">
                                <p>
                                  <ul className="uk-nav uk-navbar-dropdown-nav">
                                    <li>
                                      <a style={{ fontSize: '17px', padding: '12px 0' }} href="/page/PPCCAboutUs">{strings.PPCCAboutUs}</a>
                                    </li>
                                    <li>
                                      <a style={{ fontSize: '17px', padding: '12px 0' }} href="/content/PPCCNews">{strings.PPCCNews}</a>
                                    </li>
                                    <li>
                                      <a style={{ fontSize: '17px', padding: '12px 0' }} href="/content/PPCCMeetings">{strings.PPCCMeetings}</a>
                                    </li>
                                    <li>
                                      <a style={{ fontSize: '17px', padding: '12px 0' }} href="/content/PPCCReports">{strings.PPCCReports}</a>
                                    </li>
                                  </ul>
                                </p>
                              </div>
                            </li> */}
                            <li style={{ paddingLeft: '25px' }}>
                              <div className="uk-navbar-right">
                                <ul className="uk-navbar-nav">
                                  <li>
                                    <div className="uk-navbar-item">
                                      <a
                                        href="/contact"
                                        data-uk-icon="icon: receiver"
                                        className="contact"
                                      >
                                      </a>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="uk-navbar-item">
                                      <a
                                        className="uk-button uk-button-primary-outline"
                                        href="/sirm/login"
                                      >
                                        {strings.login}
                                      </a>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="uk-navbar-item-lang">
                                      <button className="uk-button lang" onClick={this.handleChangeLocale}>
                                        {this.state.nextLocale === 'GB' ? '🇬🇧' : '🇲🇳'}
                                      </button>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <ul className="uk-navbar-nav uk-visible@m">
                    <li className="uk-active">
                      <a href="/">{strings.aboutUs}</a>
                      <div className="uk-navbar-dropdown">
                        <ul className="uk-nav uk-navbar-dropdown-nav">
                          <li>
                            <a href="/page/aboutUs">{strings.aboutUs}</a>
                          </li>
                          {/* <li>
                            <a href="/page/headMessage">{strings.headMessage}</a>
                          </li> */}
                          <li>
                            <a href="/page/office">{strings.office}</a>
                          </li>
                          {/* <li>
                            <a href="/content/annualReport">{strings.annualReport}</a>
                          </li> */}
                        </ul>
                      </div>
                    </li>
                    <li>
                      <a href="/">{strings.onlineApplication}</a>
                      <div className="uk-navbar-dropdown">
                        <ul className="uk-nav uk-navbar-dropdown-nav">
                          <li>
                            <a href="/page/help">{strings.help}</a>
                          </li>
                          <li>
                            <a href="/page/greivanceChecklist">{strings.greivanceChecklist}</a>
                          </li>
                          <li>
                            <a href="/page/howDoWeDeal">{strings.howDoWeDeal}</a>
                          </li>
                          <li>
                            <a href="/page/faq">{strings.faq}</a>
                          </li>
                          <li>
                            <a href="/sirm/login">{strings.makeGreviance}<span style={{ color: 'green' }} data-uk-icon="icon: sign-in; ratio: 1.2"></span></a>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <a href="/">{strings.resolvedCases}</a>
                      <div className="uk-navbar-dropdown">
                        <ul className="uk-nav uk-navbar-dropdown-nav">
                          <li>
                            <a href="/knowledgeBase">{strings.knowledgeBase}</a>
                          </li>
                          <li>
                            <a href="/content/recommendation">{strings.governmentLegislation}</a>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <a href="/">{strings.information}</a>
                      <div className="uk-navbar-dropdown">
                        <ul className="uk-nav uk-navbar-dropdown-nav">
                          <li>
                            <a href="/page/guide">{strings.guide}</a>
                          </li>
                          <li>
                            <a href="/content/news">{strings.news}</a>
                          </li>
                        </ul>
                      </div>
                    </li>
                    {/* <li>
                      <a href="/">{strings.PPCC}</a>
                      <div className="uk-navbar-dropdown">
                        <ul className="uk-nav uk-navbar-dropdown-nav">
                          <li>
                            <a href="/page/PPCCAboutUs">{strings.PPCCAboutUs}</a>
                          </li>
                          <li>
                            <a href="/content/PPCCNews">{strings.PPCCNews}</a>
                          </li>
                          <li>
                            <a href="/content/PPCCMeetings">{strings.PPCCMeetings}</a>
                          </li>
                          <li>
                            <a href="/content/PPCCReports">{strings.PPCCReports}</a>
                          </li>
                        </ul>
                      </div>
                    </li> */}
                  </ul>
                </div>

                <div className="uk-navbar-right">
                  <ul className="uk-navbar-nav uk-visible@m">
                    <li>
                      <div className="uk-navbar-item">
                        <a
                          href="/contact"
                          data-uk-icon="icon: receiver"
                          className="contact"
                        >
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="uk-navbar-item">
                        <a
                          className="uk-button uk-button-primary-outline"
                          href="/sirm/login"
                        >
                          {strings.login}
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="uk-navbar-item-lang">
                        <button className="uk-button lang" onClick={this.handleChangeLocale}>
                          {this.state.nextLocale === 'GB' ? '🇬🇧' : '🇲🇳'}
                        </button>
                      </div>
                    </li>
                  </ul>
                  <a
                    className="uk-navbar-toggle uk-hidden@m"
                    href="#offcanvas"
                    uk-toggle="target: #canvas-menu"
                  >
                    <span data-uk-navbar-toggle-icon />{" "}
                    <span className="uk-margin-small-left">{strings.menu}</span>
                  </a>
                </div>

              </div>
            </div>
          </nav>
        </div>
      </header>
    );
  }
}

export default Header;
