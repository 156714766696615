import React, { Component } from "react";

import { Switch, Route } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import update from 'immutability-helper'
import jwt from 'jsonwebtoken'
import _ from 'lodash'
import AddAlert from '@material-ui/icons/AddAlert'
import Snackbar from './components/Snackbar/Snackbar'

import Header from './components/Header.jsx'
import Footer from './components/Footer.jsx'
import HomePage from './views/HomePage/HomePage.jsx'
import NewsPage from './views/NewsPage/NewsPage.jsx'
import NewsDetail from './views/NewsPage/NewsDetail.jsx'
import ContactPage from './views/ContactPage/ContactPage.jsx'
import Page from './views/Page/Page'
import CasesPage from './views/CasesPage/CasesPage'
import ErrorPage from './views/ErrorPage/ErrorPage.jsx'

import { availableLocales, getLocale } from './misc/localeManager' 
import { loadState, saveState, removeState } from './misc/localStorage'
import AppProvider from './misc/providers'
import strings from "./locale/strings"
import { combineURLs } from './misc/utils'
import 'moment/locale/mn'

class App extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loaded: false,
      locale: 'mn',
      token: null,
      notification: { text: '', open: false, color: 'danger' }
    }
  }  

  showNotification = (text, color) => {
    this.setState({
      notification: { text, color, open: true }
    })
    if (this.timer) {
      clearTimeout(this.timer)
    }
    this.timer = setTimeout(
      function () {
        this.setState({
          notification: update(this.state.notification, { 'open': { $set: false } })
        })
      }.bind(this),
      6000
    )
  }

  getToken = async () => {
    try {
      let token = loadState('token')

      if (token) {
        const payload = jwt.decode(token)
        const exp = moment.unix(payload.exp)
        const duration = moment.duration(exp.diff(moment()))
        if (exp.isBefore(moment())) { // expired
          removeState('token')
          this.setState({
            token: null,
            loaded: true
          })
          return
        }
        if (duration.asHours() < 1) {
          // refresh
          try {
            const refreshRes = await axios.get(
              combineURLs(process.env.REACT_APP_API_URI, 'refresh'),
              {
                headers: {
                  'Authorization': `Bearer ${token}`
                },
                timeout: 10000
              }
            )
            console.log('refreshRes', refreshRes)
            token = _.get(refreshRes, 'data.token')
            saveState('token', token)
          } catch (error) {
            this.setState({ loading: false })
            console.error(error)
          }
        }
      }

      this.setState({
        token,
        loaded: true
      })
    } catch (error) {
      console.error(error)
    }
  }

  loadLocale = () => {
    const { locale } = getLocale()
    this.changeLocale(locale)
  }

  toggleLocale = () => {
    const { nextLocale } = getLocale()
    this.changeLocale(nextLocale)
  }

  changeLocale = (locale) => {
    if (!availableLocales.includes(locale)) {
      return
    }

    try {
      strings.setLanguage(locale)
      saveState('locale', locale)
      moment.locale(locale)
      this.setState({
        locale
      })
    } catch (error) {
      console.error(error)
    }
  }  

  async componentDidMount () {
    this.loadLocale()
    this.getToken()
  }

  componentWillUnmount () {
    if (this.timer) {
      clearTimeout(this.timer)
    }    
  }  

  render() {
    return (
      <AppProvider.Provider value={{
        token: this.state.token,
        locale: this.state.locale,
        showNotification: this.showNotification,
        toggleLocale: this.toggleLocale,
        handleError: (error, errorMessage) => {
          console.error(error)
          console.log(error.response)
          const httpStatus = _.get(error, 'response.status')
          const errorCode = _.get(error, 'response.data.code', 999)
          if (httpStatus === 401 && errorCode === 997) {
            removeState('token')
            this.setState({
              token: null,
              loaded: true
            })
          } else {
            this.showNotification(errorMessage || strings.errorInternal, 'danger')
          }
        }
      }}>
        <Header/> 
        <Switch>
            <Route 
            path={'/contentdetail/:newsKey'}
            component={NewsDetail}
            key={'news'}
            />
            <Route
            path={'/page/:pageKey'}
            component={Page}
            key={'page'}
            />
            <Route
            path={'/content/:categoryKey'}
            component={NewsPage}
            key={'news'}
            />
            <Route
            path={'/contact'}
            component={ContactPage}
            key={'contact'}
            />
            <Route
            path={'/knowledgeBase/:tabId/:uuid'}
            component={CasesPage}
            key={'knowledge-base'}
            />
            <Route
            path={'/knowledgeBase/:tabId/'}
            component={CasesPage}
            key={'knowledge-base'}
            />
            <Route
            path={'/knowledgeBase'}
            component={CasesPage}
            key={'knowledgeBase'}
            />
            <Route
            exact path={'/'}
            component={HomePage}
            key={'home'}
            />
            <Route component={ErrorPage} />
        </Switch>
        <Snackbar
          place='tr'
          color={this.state.notification.color}
          icon={AddAlert}
          message={this.state.notification.text}
          open={this.state.notification.open}
        />        
        <Footer/>
      </AppProvider.Provider>      
    )
  }
}

export default App
