import React, { useReducer, useEffect, useContext } from 'react'
import axios from 'axios'
import { NavLink } from 'react-router-dom'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import CircularProgress from '@material-ui/core/CircularProgress'

// @material-ui/icons
// core components
import GridContainer from '../../components/Grid/GridContainer.jsx'
import Pagination from '../../components/Pagination/Pagination.jsx'
import { combineURLs } from '../../misc/utils'
import styles from '../../assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.jsx'
// import AppProvider from '../../misc/providers'
import strings from '../../locale/strings'
import { getLocale } from '../../misc/localeManager'

const NewsPage = ({ classes, ...props }) => {
  // const context = useContext(AppProvider)
  const { locale, nextLocale } = getLocale()

  // const textTruncate = (str, length, ending) => {
  //   if (length == null) {
  //     length = 72
  //   }
  //   if (ending == null) {
  //     ending = '...'
  //   }
  //   if (str.length > length) {
  //     return str.substring(0, length - ending.length) + ending
  //   } else {
  //     return str
  //   }
  // };   

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      news: [],
      loading: false,
      loaded: false,
      total: -1,
      currentPage: 1,
      perpage: 6,
      pages: []
    }
  )

  const goToNextPage = () => {
    console.log('goToNextPage', state.currentPage)
    if (state.currentPage + 1 <= state.total) {
      setState({ currentPage: state.currentPage + 1 })
      loadNews(state.currentPage)
    }
  }

  const goToPrevPage = () => {
    console.log('goToPrevPage', state.currentPage)
    if (state.currentPage - 1 >= 1) {
      setState({ currentPage: state.currentPage - 1 })
      loadNews(state.currentPage)
    }
  }

  const goToPage = (page) => {
    if (page > 0 && page <= state.total) {
      console.log('setState')
      setState({ currentPage: page })
      loadNews(page)
    }
  }

  const loadNews = async (page) => {
    console.log('loadNews=', page)
    window.scrollTo(0, 0)
    document.body.scrollTop = 0
    if (state.loading) {
      return
    }

    setState({
      loading: true
    })

    let totalPage = 1
    try {
      let language = locale === 'MN' ? 'mn' : 'en'

      let newsRes = await axios.get(combineURLs(process.env.REACT_APP_API_URI,
        'news?offset=' + (page - 1) * state.perpage +
        '&categoryKey=' + props.match.params.categoryKey +
        '&limit=' + state.perpage +
        '&language=' + language))

      console.log('newsRes', newsRes)

      console.log('newsRes', parseInt(newsRes.data.total) / state.perpage)
      totalPage = Math.ceil(1.0 * parseInt(newsRes.data.total) / state.perpage)
      console.log('totalPage', totalPage)
      setState({
        news: newsRes.data.news,
        loading: false,
        loaded: true,
        total: totalPage,
        currentPage: page
      })
    } catch (error) {
      console.log(error)

      setState({
        loading: false
      })
    }
  }

  useEffect(() => { loadNews(state.currentPage) }, [locale])
  useEffect(() => { loadNews(1) }, [props.match.params.categoryKey])

  const renderNewsCards = () => {
    return (
      state.news.map(function (newsItem, id) {
        const { title, coverImage: coverImageUrl, newsKey } = newsItem
        return (
          <div className="news-page">
            <div className="uk-card uk-card-default uk-box-shadow-medium uk-card-hover uk-card-body uk-inline border-radius-large border-xlight uk-card-customized">
            <NavLink
              to={`/contentdetail/${newsKey}`}
              activeClassName='active'
              key={newsKey}
            >
                {/* <a className="uk-position-cover" href={`/contentdetail/${newsKey}`}> </a> */}
                {/* <span data-uk-icon="icon: cog; ratio: 3.4" className="data-uk-icon"/> */}
                <div className="news-cover">
                  <img src={coverImageUrl} alt=""></img>
                </div>
                <h3 className="uk-card-title uk-margin news-title">{title}</h3>
            </NavLink>
            </div>
          </div>          
        )
      }))
  }

  let pages = []
  let { loaded, loading } = state
  if (loaded) {
    console.log('total=', state.total)

    console.log('state in render', state)

    if (state.currentPage > 1) {
      pages.push(
        { text: strings.previous, onClick: goToPrevPage })
    }
    pages.push({ text: 1, onClick: goToPage, active: state.currentPage === 1 })

    if (state.currentPage !== 1 && state.currentPage !== state.total) {
      if (state.currentPage - 1 > 2) {
        pages.push({ text: '●●●', disabled: true })
      }
      if (state.currentPage - 1 >= 2) {
        pages.push({ text: state.currentPage - 1, onClick: goToPage })
      }

      pages.push({ text: state.currentPage, onClick: goToPage, active: true })

      if (state.currentPage + 1 < state.total) {
        pages.push({ text: state.currentPage + 1, onClick: goToPage })
      }

      if (state.currentPage + 2 < state.total) {
        pages.push({ text: '●●●', disabled: true })
      }
    } else {
      if (state.total > 3) {
        pages.push({ text: '●●●', disabled: true })
      } else if (state.total === 3) {
        pages.push({ text: 2, onClick: goToPage })
      }
    }

    if (state.total > 1) { pages.push({ text: state.total, onClick: goToPage, active: state.currentPage === state.total }) }

    if (state.currentPage < state.total) {
      pages.push(
        { text: strings.next, onClick: goToNextPage })
    }
  }

  return (
    <div className={classes.newsSection}>
      <div className={classes.section}>
        <div>
          <div className={classes.container}>
            <div className={classes.title}>
              <h1>{strings[props.match.params.categoryKey]}</h1>
            </div>
            {
              loading
                ? <div className={classes.loaderContainer}>
                  <CircularProgress className={classes.loader} size={60} thickness={5} />
                </div>
                : state.news.length === 0
                  ? <div className={classes.loaderContainer}>
                    <h1>{strings.contentNotFound}</h1>
                  </div>
                  : <div>
                      
                        <div
                          className="uk-child-width-1-3@m uk-grid-match uk-text-center uk-margin-medium-top"
                          data-uk-grid
                        >
                        {renderNewsCards()}  
                        </div>
                      
                      {/* <GridContainer>
                        {renderNewsCards()}
                      </GridContainer> */}
                      <div className={classes.justifyContentCenter}>
                        <Pagination
                          pages={pages}
                        />
                      </div>
                    </div>
             }
          </div>
        </div>
      </div>
    </div>
  )
}

export default withStyles(styles)(NewsPage)
