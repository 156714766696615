import React, { useReducer, useEffect, useContext } from 'react'
import axios from 'axios'
import { combineURLs } from '../../misc/utils'
import strings from '../../locale/strings'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import CircularProgress from '@material-ui/core/CircularProgress'
 
// core components
import GridContainer from '../../components/Grid/GridContainer.jsx'
import GridItem from '../../components/Grid/GridItem.jsx'
import NavPills from '../../components/NavPills/NavPills.jsx'
import pillsStyle from '../../assets/jss/material-kit-pro-react/views/componentsSections/pillsStyle.jsx'

import CasesTable from './CasesTable.jsx'
import CaseDetails from './CasesDetails.jsx'
import AppProvider from '../../misc/providers'
import { getLocale } from '../../misc/localeManager'

const CasesPage = ({ classes, ...props }) => {

  const context = useContext(AppProvider)

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      grievanceTypes: [],
      loading: false,
      loaded: false,
      tabId: 0
    }
  )

  const loadData = async () => {
    if (state.loading) {
      return
    }
    setState({
      loading: true
    })
    try {
      const { locale, nextLocale } = getLocale()
      let language = locale === 'MN' ? 'mn' : 'en'

      const grievanceTypesRes = await axios.get(
        combineURLs(process.env.REACT_APP_API_URI, 'knowledge-base/grievance-types?language=' + language)
      )
      console.log('grievanceTypesRes', grievanceTypesRes)

      setState({
        grievanceTypes: grievanceTypesRes.data.grievanceTypes,
        loaded: true,
        loading: false,
        tabId: 0
      })
    } catch (error) {
      setState({
        loadFailed: true,
        loading: false,
        tabId: 0
      })
    }
  }

  const renderTabs = () => {
    const { match: { params } } = props
    const { uuid, tabId } = params
    let { grievanceTypes } = state
    let language = context.locale === 'MN' ? 'mn' : 'en'
    console.log('grievanceTypes', grievanceTypes)

    //grievanceTypes.length = 0 ireed baigaa bolohoor bainga paramiin tabId = 0 bolgochood baisan baina
    // if (grievanceTypes.length < tabId) {
    //   props.history.push('/knowledgeBase/' + 0)
    // }

    return (
      grievanceTypes.map((type, i) => {
        return (
          {
            tabButton: type.value,
            tabContent: (
              tabId != null && parseInt(tabId) === i && uuid
                ? <CaseDetails uuid={uuid} typeTitle={type.value} language={language} />
                : <CasesTable type={type} tabId={i} language={language} />
            )
          }
        )
      })
    )
  }

  const handleChange = (tabId) => {
    console.log('tabId', tabId)
    props.history.push('/knowledgeBase/' + tabId)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { loadData() }, [context.locale])

  const { match: { params } } = props
  const { tabId } = params
  let { loading } = state

  console.log('params', params)

  if (loading) {
    return (
      <div className={classes.loaderContainer}>
        <CircularProgress className={classes.loader} size={60} thickness={5} />
      </div>
    )
  }

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <div id='navigation-pills'>
          <div className={classes.title} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <h1>{strings.knowledgeBase}</h1>
          </div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <NavPills
                handleChange={handleChange}
                horizontal={{
                  tabsGrid: { xs: 12, sm: 4, md: 3 },
                  contentGrid: { xs: 12, sm: 8, md: 9 }
                }}
                active={parseInt(tabId) || 0}
                color='primary'
                tabs={
                  renderTabs()
                }
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  )
}

export default withStyles(pillsStyle)(CasesPage)
