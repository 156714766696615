import {
  container,
  title,
  main,
  whiteColor,
  blackColor,
  grayColor,
  mainRaised,
  hexToRgb,
  primaryColor
} from '../../material-kit-pro-react.jsx'
import footerStyle from '../../material-kit-pro-react/views/componentsSections/footerStyle.jsx'

const presentationStyle = {
  ...footerStyle,
  main: {
    ...main,
    /* overflow: "hidden" */
    background: '#FFFFFF',
    position: 'relative',
    zIndex: '3',
    marginBottom: '20px'
  },
  mainRaised,
  parallax: {
    minHeight: '100vh',
    overflow: 'hidden',
    '@media (min-width: 576px)': {
      margin: '70px 2px 20px 2px'
    },
    '@media (min-width: 768px)': {
      margin: '70px 5px 20px 5px'
    },
    '@media (min-width: 992px)': {
      margin: '70px 10px 20px 10px'
    },
    '@media (min-width: 1200px)': {
      margin: '70px 30px 20px 30px'
    },
    borderRadius: '6px',
    boxShadow:
      '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)'
  },
  mainRaisedTop: {
    minHeight: '70vh',
    overflow: 'scroll',
    '@media (min-width: 576px)': {
      margin: '70px 2px 20px 2px'
    },
    '@media (min-width: 768px)': {
      margin: '70px 5px 20px 5px'
    },
    '@media (min-width: 992px)': {
      margin: '70px 10px 20px 10px'
    },
    '@media (min-width: 1200px)': {
      margin: '70px 30px 20px 30px'
    },
    borderRadius: '6px',
    boxShadow:
      '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)'
  },
  container: {
    ...container,
    zIndex: 1
  },
  title: {
    ...title,
    fontSize: '2.4rem',
    display: 'inline-block',
    position: 'relative',
    marginTop: '30px',
    minHeight: '32px',
    color: blackColor,
    textDecoration: 'none',
    // textShadow: '2px 2px #000',
    fontWeight: '700'
    // '@media (min-width: 576px)': {
    //   maxWidth: '540px'
    // },
    // '@media (min-width: 768px)': {
    //   maxWidth: '720px'
    // }
  },
  subtitle: {
    fontSize: '1.313rem',
    maxWidth: '700px',
    fontWeight: '600',
    margin: '10px auto 0',
    color: blackColor
    // textShadow: '2px 2px #000'
  },
  subtitleSmall: {
    fontSize: '1rem',
    maxWidth: '700px',
    fontWeight: '500',
    color: blackColor,
    margin: '10px auto 0'
    // textShadow: '2px 2px #000'
  },
  brand: {
    color: blackColor,
    textAlign: 'center',
    '& h1': {
      fontSize: '4.2rem',
      fontWeight: '600',
      display: 'inline-block',
      position: 'relative'
    }
  },
  proBadge: {
    position: 'relative',
    fontSize: '22px',
    textTransform: 'uppercase',
    fontWeight: '700',
    right: '-10px',
    padding: '10px 18px',
    top: '-30px',
    background: whiteColor,
    borderRadius: '3px',
    color: grayColor[18],
    lineHeight: '22px',
    boxShadow: '0 5px 5px -2px rgba(' + hexToRgb(grayColor[25]) + ',.4)'
  },
  loader: {
    color: primaryColor[0]
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '50vh',
    padding: '100px'
  },
  logoStyle: {
    height: '75px',
    '@media (min-width: 576px)': {
      height: '65px'
    },
    '@media (min-width: 768px)': {
      height: '65px'
    },
    '@media (min-width: 992px)': {
      height: '70px'
    },
    '@media (min-width: 1200px)': {
      height: '75px'
    }
  }
}

export default presentationStyle
