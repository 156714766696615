import { loadState } from '../misc/localStorage'

export const defaultLocale = 'mn'
export const availableLocales = [
  'MN',
  'GB'
]

export const getLocale = () => {
  let localeState = loadState('locale')
  let locale = defaultLocale
  let nextLocale = availableLocales[0]
  if (availableLocales.includes(localeState)) {
    locale = localeState
  }

  const currentIdx = availableLocales.indexOf(locale)
  if (currentIdx < availableLocales.length - 1) {
    nextLocale = availableLocales[currentIdx + 1]
  }

  return {
    locale,
    nextLocale
  }
}
