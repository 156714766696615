import React, { useReducer, useEffect, useContext } from 'react'
import axios from 'axios'
import update from 'immutability-helper'
import _ from 'lodash'
// core components
import Button from '../components/CustomButtons/Button.jsx'
import ValidInput from '../components/Validator/ValidInput'
import Validator from '../components/Validator/Validator'
import ReCAPTCHA from 'react-google-recaptcha'
import { combineURLs } from '../misc/utils'
import AppProvider from '../misc/providers'
import strings from "../locale/strings"
import { DialogContent, Dialog, DialogActions, DialogTitle } from '@material-ui/core'

const Subscribe = ({ classes, ...props }) => {

  const context = useContext(AppProvider)

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      email: '',
      captcha: null,
      dialog: false,
      validated: new Set(),
      validationErr: new Set(),
      captchaSuccess: false
    }
  )

  const recaptchaRef = React.createRef()

  const handleChange = (name, value, result) => {
    let { validated, validationErr } = state
    if (result === Validator.RESULT.VALID) {
      validated = update(validated, { $add: [name] })
      validationErr = update(validationErr, { $remove: [name] })
    } else if (result === Validator.RESULT.INVALID) {
      validated = update(validated, { $remove: [name] })
      validationErr = update(validationErr, { $add: [name] })
    } else if (result === Validator.RESULT.EMPTY) {
      validated = update(validated, { $remove: [name] })
      validationErr = update(validationErr, { $remove: [name] })
    }
    setState({
      [name]: value,
      validationErr,
      validated
    })
  }

  const verifyCallback = (recaptchaToken) => {
    console.log('CAPTHCA', recaptchaToken)
    setState({
      captcha: recaptchaToken
    })
  }

  const handleSubmit = async () => {
    let { validationErr, validated, email, captcha } = state
    if (validationErr.size > 0) {
      setState({
        validationErr
      })
      context.showNotification(strings.enterValidEmail, 'danger')
    } else {
      try {
        const subscribeRes = await axios.post(
          combineURLs(process.env.REACT_APP_API_URI, `newsletter/`),
          {
            email,
            captcha
          },
          {
            headers: {
              'Authorization': `Bearer ${context.token}`
            },
            timeout: 10000
          }
        )
        console.log('updateUserRes', subscribeRes)
        setState({
          email: '',
          loading: false,
          dialog: false
        })
        context.showNotification(strings.newsletterSuccess, 'success')
      } catch (error) {
        setState({
          loading: false
        })
        const httpStatus = _.get(error, 'response.status')
        const errorCode = _.get(error, 'response.data.code', 999)
        const displayInfo = _.get(error, 'response.data.displayInfo')
        if (displayInfo) {
          context.showNotification(displayInfo, 'danger')
        } else if (httpStatus === 409 && errorCode === 995) {
          context.showNotification(strings.userEmailConflict, 'danger')
        } else {
          context.handleError(error)
        }
      }
      try {
        recaptchaRef.current.reset()
      } catch (e) {
      }
    }
  }

  return (
    <div className="uk-section uk-text-center">
      <Dialog
        open={state.dialog}
        onClose={() => {
          setState({
            dialog: false
          })
        }}
      >
      <div className="dialog">
        <DialogTitle>
          {strings.subscribe}
        </DialogTitle>
        <DialogContent>
          <ValidInput
            labelText={strings.youremail}
            labelProps={{
              required: true
            }}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              type: 'email',
              name: 'email',
              value: state.email || ''
            }}
            email
            notEmpty
            success={state.validated.has('email')}
            error={state.validationErr.has('email')}
            onChange={handleChange}
          />
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={verifyCallback}
          />
          </DialogContent>
          <DialogActions>
            <Button round block color='primary' onClick={async () => { await handleSubmit() }}>
              {strings.subscribe}
            </Button>
          </DialogActions>
        </div>
      </Dialog>    

      <div className="uk-container uk-container-small">
        <div data-uk-scrollspy="cls: uk-animation-slide-bottom-medium; repeat: true">
          {/* <h2 className="uk-h1 uk-margin-bottom">{strings.serurity}</h2> */}
          <p className="uk-text-lead uk-text-center">
            {strings.newsletterHintText}
          </p>
          <br/>
          <Button
            className="subscribe"
            onClick={() => {
                        setState({ dialog: true })
                      }}
          >
            {strings.subscribe}
          </Button>              
        </div>
      </div>
    </div>
  );
}

export default Subscribe;
