import {
  container,
  title,
  cardTitle,
  coloredShadow,
  description,
  mlAuto,
  mrAuto,
  grayColor,
  primaryColor
} from '../../../material-kit-pro-react.jsx'

const blogsSection = {
  container,
  title,
  coloredShadow,
  cardTitle,
  mlAuto,
  mrAuto,
  description,
  blog: {
    padding: '50px 0',
    textAlign: 'center'
  },
  cardCategory: {
    marginBottom: '0',
    marginTop: '10px',
    '& svg,& .fab,& .fas,& .far,& .fal,& .material-icons': {
      position: 'relative',
      top: '8px',
      lineHeight: '0'
    }
  },
  description1: {
    ...description,
    textAlign: 'justify',
    lineHeight: '1.313rem'
  },
  author: {
    '& a': {
      color: grayColor[1],
      textDecoration: 'none'
    }
  },
  card: {
    // marginBottom: '80px'
  },
  card4: {
    marginBottom: '60px',
    textAlign: 'center'
  },
  cardNews: {
    marginBottom: '60px',
    textAlign: 'justify'
  },
  cardImage: {
    marginTop: '20px!important',
    maxHeight: '50vh'
  },
  loader: {
    color: primaryColor[0]
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '50vh',
    padding: '100px'
  },
  justifyCenter: {
    justifyContent: 'center',
    alignItems: 'center'
  }
}

export default blogsSection
