import React from "react";
import strings from "../locale/strings"
import SearchForm from "./SearchForm.jsx";

class Banner extends React.Component {
  render() {
    return (
      <div
        className="uk-section section-hero uk-position-relative"
        data-uk-scrollspy="cls: uk-animation-slide-bottom-medium; repeat: true"
      >
        <div className="uk-container uk-container-small">
          <p className="hero-image uk-text-center">
            <img src="img/head_img.svg" alt="Hero" />
          </p>
          <h1 className="uk-heading-medium uk-text-center uk-margin-remove-top">
            {strings.bannerTitle}
          </h1>
          <p className="uk-text-lead uk-text-center">
            {strings.bannerDesc}
          </p>
          <div className="hero-search">
            <div className="uk-position-relative">
              <SearchForm></SearchForm>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Banner;
