import React from "react";

class Logo extends React.Component {
  render() {
    return (
      <a className="uk-navbar-item uk-logo" href="/">
        <img
          src="/img/ipc_logo.png"
          width={240}
          alt=""
        />
      </a>
    );
  }
}

export default Logo;
