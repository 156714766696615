import React from 'react'
import axios from 'axios'
import moment from 'moment'
import { combineURLs } from '../../misc/utils'
import strings from '../../locale/strings'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import CircularProgress from '@material-ui/core/CircularProgress'

// core components
import GridContainer from '../../components/Grid/GridContainer.jsx'
import GridItem from '../../components/Grid/GridItem.jsx'
import Card from '../../components/Card/Card.jsx'
import CardBody from '../../components/Card/CardBody.jsx'

import styles from '../../assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.jsx'
import { Divider } from '@material-ui/core'

class Case extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      kb: null,
      loading: false,
      loaded: false
    }
  }

    componentDidMount = () => {
      this.loadData()
    }

    loadData = async () => {
      console.log('loadData', this.props)
      if (this.state.loading) {
        return
      }
      this.setState({
        loading: true
      })
      try {
        let uuid = this.props.uuid
        const kbRes = await axios.get(
          combineURLs(process.env.REACT_APP_API_URI, 'knowledge-base/' + uuid + '?language=' + this.props.language)
        )
        console.log('kbRes', kbRes)

        this.setState({
          kb: kbRes.data.knowledgeBase,
          loaded: true,
          loading: false
        })
      } catch (error) {
        this.setState({
          loadFailed: true,
          loading: false
        })
      }
    }
    render () {
      const { classes, typeTitle } = this.props
      let { loading, kb } = this.state
      if (loading || kb == null) {
        return (
          <div className={classes.loaderContainer}>
            <CircularProgress className={classes.loader} size={60} thickness={5} />
          </div>
        )
      } else {
        return (
          <div className={classes.section}>
            <div>
              {/* <div className={classes.title}>
                <h2>{typeTitle}</h2>
              </div> */}
              <Divider />
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <Card>
                    <CardBody>
                      <GridContainer style={{ border: '1px solid #e5e5e6' }}>
                        <GridItem xs={12} sm={12} md={4} lg={2} style={{ justify: 'center' }}>
                          <div className="title-padding">
                            <h6>{strings.newsTitle}</h6>
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={8} lg={10} style={{ justify: 'center', borderLeft: '1px solid #e5e5e6' }}>
                          <div className="title-padding">
                            <h6>{kb.title}</h6>
                          </div>
                        </GridItem>
                      </GridContainer>

                      <GridContainer style={{ border: '1px solid #e5e5e6', marginTop: '20px' }}>
                        <GridItem xs={12} sm={4} md={3} lg={3} style={{ justify: 'center' }}>
                          <div className="title-padding">
                            <h6>{strings.relatedOrgs}</h6>  
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={8} md={3} lg={3} style={{ justify: 'center', borderLeft: '1px solid #e5e5e6' }}>
                          <div className="title-padding">
                            <h6>{kb.orgs.map(function (org) {
                                return org.name
                              }).join(',') || ''}</h6>
                          </div>
                        </GridItem>

                        <GridItem xs={12} sm={4} md={3} lg={3} style={{ justify: 'center', borderLeft: '1px solid #e5e5e6' }}>
                          <div className="title-padding">
                            <h6>{strings.relatedLaws}</h6>
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={8} md={3} lg={3} style={{ justify: 'center', borderLeft: '1px solid #e5e5e6' }}>
                          <div className="title-padding">
                            {kb.regulation || ''}
                          </div>
                        </GridItem>
                      </GridContainer>

                      <GridContainer style={{ border: '1px solid #e5e5e6', marginTop: '20px' }}>
                        <GridItem xs={12} sm={4} md={3} lg={3} style={{ justify: 'center' }}>
                          <div className="title-padding">
                            <h6>{strings.grievanceDate}</h6>
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={8} md={3} lg={3} style={{ justify: 'center', borderLeft: '1px solid #e5e5e6' }}>
                          <div className="title-padding">
                            {moment(kb.grievanceDate).format('YYYY-MM-DD') || ''}
                          </div>
                        </GridItem>

                        <GridItem xs={12} sm={4} md={3} lg={3} style={{ justify: 'center', borderLeft: '1px solid #e5e5e6' }}>
                          <div className="title-padding">
                            <h6>{strings.resolutionDate}</h6>
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={8} md={3} lg={3} style={{ justify: 'center', borderLeft: '1px solid #e5e5e6' }}>
                          <div className="title-padding">
                            {moment(kb.resolutionDate).format('YYYY-MM-DD') || ''}
                          </div>
                        </GridItem>
                      </GridContainer>

                      <GridContainer style={{ border: '1px solid #e5e5e6', marginTop: '20px' }}>
                        <GridItem xs={12} sm={4} md={12} lg={12} style={{ justify: 'center', borderBottom: '1px solid #e5e5e6' }}>
                          <div className="title-padding">
                            <h6>{strings.shortGrievanceDesc}</h6>
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={12} lg={12} style={{ justify: 'center' }}>
                          <div className="title-padding">
                            <p>{kb.grievance}</p>
                          </div>
                        </GridItem>
                      </GridContainer>

                      <GridContainer style={{ border: '1px solid #e5e5e6', marginTop: '20px' }}>
                        <GridItem xs={12} sm={4} md={12} lg={12} style={{ justify: 'center', borderBottom: '1px solid #e5e5e6' }}>
                          <div className="title-padding">
                            <h6>{strings.resoulutionDesc}</h6>
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={12} lg={12} style={{ justify: 'center' }}>
                          <div className="title-padding">
                            <p>{kb.resolution}</p>
                          </div>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </div>
          </div >
        )
      }
    }
}

export default withStyles(styles)(Case)
