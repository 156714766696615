import LocalizedStrings from "react-localization";

export const LocalStrings = {
  GB: {
    // Menus
    home: "Home",
    aboutUs: "About Us",
    headMessage: "Head of the Council's Message",
    office: "Office",
    annualReport: "Annual Report",
    contactUs: "Contact Us",
    onlineApplication: "Online Application",
    onlineApplicationDesc: "Our doors are always open",
    help: "How can the SIRM help?",
    greivanceChecklist: "Checklist for a Grievance",
    howDoWeDeal: "How do we deal with Grievance?",
    makeGreviance: "Make a Grievance",
    resolvedCases: "Resolved Cases",
    knowledgeBase: "Knowledge Base",
    knowledgeBaseDesc: "How Thins Are Improved",
    regulatoryInformation: "Regulatory Information",
    regulatoryInformationDesc: "Regulatory Information for a Foreign Investors",
    guide: "Guide",
    governmentLegislation: "Government Legislation",
    PPCC: "PPCC",
    PPCCAboutUs: "PPCC About Us",
    PPCCNews: "PPCC News",
    PPCCMeetings: "PPCC Meetings",
    PPCCReports: "PPCC Reports",
    notice: "Notice",
    usefulSites: "Useful Sites",
    customerService: "Customer Service Center",
    privacyPolicy: "Privacy Policy",
    sitemap: "Sitemap",
    recommendation: "IPC Recommendations",
    meetings: "Meetings",
    reports: "Reports",
    information: "Information",

    // Buttons
    create: "Create",
    update: "Update",
    login: "Login",
    formSubmit: "Submit",
    continue: "Continue",
    cancel: "Cancel",
    delete: "Delete",
    loading: "Loading",
    more: "More",
    previous: "Previous",
    next: "Next",

    // Register
    register: "Register",
    email: "Email",
    password: "Password",
    passwordRepeat: "Password Repeat",

    // Labels
    labelEmail: "Email",
    labelPassword: "Password",

    // Tooltips
    changeLocale: "Change Language",
    processEdit: "Edit Process",
    processNew: "Create New Process",
    formEdit: "Edit Form",
    formNew: "Create New Form",
    formFieldEdit: "Edit Form Field",
    formFieldNew: "Create New Form Field",
    fieldEdit: "Edit Field",
    fieldNew: "Create New Field",
    backToProcessList: "Back to Process List Screen",
    moveUp: "Move Up",
    moveDown: "Move Down",
    saveOrder: "Save order",
    save: "Save",

    // Notifications
    updateSuccessful: "Successfully updated",
    insertSuccessful: "Successfully created",

    // Errors
    errorUnauthorized: "Email or password incorrect",
    errorInternal: "Error occurred",
    notFound: "Page Not Found!",
    newsNotFound: "News Not Found!",
    contentNotFound: "Content Not Found!",

    // Sidebar
    sidebarGrievances: "Grievances",
    sidebarProcesses: "Processes",
    sidebarReferences: "References",

    // References
    references: "References",
    referenceNew: "Create New Reference",
    referenceEdit: "Edit Reference Data",
    referenceName: "Name of Reference",
    referenceLabel: "Label",
    referenceValue: "Label",
    referenceKey: "Reference Key",
    referenceAddValue: "Add New Value",
    referenceEditValue: "Edit Value",
    referenceDeleteValue: "Delete Value",
    referenceDeleteValueConfirm: "Are you sure to delete this value",
    referenceSubLevel: "Manage Sub Level",
    referenceSave: "Save Reference Data",

    // Builder
    // Tabs
    processEditor: "Process",
    processDiagram: "Diagram",
    formEditor: "Form",
    formFieldEditor: "Form Fields",
    formGroupFieldEditor: "Group Fields",
    formPreviewer: "Preview",
    fieldEditor: "Process Fields",

    // Process fields
    processKey: "Camunda Process Key",
    processName: "Name",
    processStartable: "Is Startable Process?",
    processPublishedVer: "Published Version",

    // Process version fields
    procVerVersion: "Version",
    procVerCreated: "Created At",
    procVerDescription: "Description",
    procVerDiagram: "Diagram",
    procVerViewDiagram: "View Diagram",
    procVerIsPublished: "Is Published Version?",
    procVerPublish: "Publish",
    procVerPublishNew: "Publish New Version",
    procVerPublishExisting: "Publish Existing Version",

    // Form fields
    formKey: "Camunda Form Key",
    formName: "Name",
    formDescription: "Description",
    formDueTimer: "Camunda Due Timer",

    // Field fields
    fieldLabel: "Label",
    fieldType: "Type",
    fieldReadOnly: "Is read only",
    fieldDefaultVal: "Default value",

    // Other - TODO
    userGrievanceTasks: "Grievance Tasks",
    taskListEmpty: "No tasks available",
    processes: "Processes",
    formList: "Form List",
    fieldList: "Field List",
    choose: "Choose from list",
    processNotDeployed: "Process not deployed yet",
    yes: "Yes",
    no: "No",
    na: "N/A",
    createdAt: "Created At",
    actions: "Actions",
    confirmDialogTitle: "Are you sure",

    // News
    newsPageTitle: "News",
    news: "News",
    newsNew: "Create News",
    newsEdit: "News Edit",
    newsTitle: "Title",
    newsKey: "News id",
    publish: "Publish",
    unpublish: "Unpublish",

    // KnowledgeBase
    knowledgeCategory: "Knowledge Base Category",
    relatedLaws: "Related law",
    relatedOrgs: "Related organization",
    grievanceDate: "Issued date",
    resolutionDate: "Resolved date",
    shortGrievanceDesc: "Short description",
    resoulutionDesc: "Resolution",
    knowledgeBaseEmpty: "Currently there is no resolved grievance.",
    seeList: "See the list",

    // Newsletter
    subscribe: "Subscribe",
    youremail: "Your Email...",
    newsletterTitle: "Subscribe to our NewsLetter.",
    newsletterSuccess: "Successfully Subscribed to our newsletter",
    newsletterHintText: "Join our newsletter and get news in your inbox.",
    enterValidEmail: "Please enter valid email address",

    faq: "FAQ",
    faqDesctiption: "Top 5 frequently asked questions",
    faqHowUrl: "/img/how-en.png",

    // Footer
    addressTitle: "Address",
    addressLine:
      "Улаанбаатар хот, Чингэлтэй дүүрэг, Нэгдсэн Үндэстний Гудамж 5/1, Засгийн газрын ll байр, Үндэсний хөгжлийн газар",
    phoneTitle: "Phone",
    emailTitle: "Email",
    socialTitle: "Social",
    copyrightLine: "Investor Protection Council.  ©",

    //Video Intro
    video: "Video introduction",
    videoDescription: "Briefly about us",

    //Security
    serurity: "Security guarantee",
    securityDescription:
      "The EITI shall not use or disseminate citizen or legal entity information to third parties and shall not use it for any purpose other than to resolve grievances.",
    guideButton: "User guide",

    onlineApplicationDescription:
      "As an investor, click here to connect to the grievance system.",
    knowledgeBaseDescription:
      "Click here to learn more about our complaints and how they are resolved.",
    regulatoryInformationDescription:
      "Click here to read investment laws, international agreements and conventions.",

    bannerTitle: "How can we help you?",
    bannerDesc:
      "An investor system that allows investors to monitor the resolution of their grievances, facilitates the exchange of information between government agencies",
    searchButton: "Search for information ...",

    processTitle: "How do we resolve your complaint?",
    processDesc: "Complaints are categorized by type",
    processChoice1: "License",
    processChoice2: "Inspection",
    processChoice3: "Taxes",
    processChoice4: "Employment",
    processChoice5: "Visa",
    processChoice6: "Execution of the contract",
    processChoice7: "Confiscation of property",
    processChoice8: "Currency transfers",
    processChoice9: "Discrimination",
    processChoice10: "Other",

    contactSection1:
      "Government Building 2 United Nations Street 5/1 Ulaanbaatar 15160",
    contactSection2:
      "Investor Protection Council Office email",
    contactSection2Email: "contact@ipc.gov.mn",
    contactSection2Href: "mailto:contact@ipc.gov.mn",
    contactSection3:
      "Investor Protection Council Office, MED Head of Investment Promotion Division G.Nasanbuyan",
    contactSection3Email: "nasanbuyan.g@med.gov.mn",
    contactSection3Href: "mailto:nasanbuyan.g@med.gov.mn",
    contactSection3Phone: " +976-51-264182",
    contactSection4:
      "Investor Protection Council Office, SIRM system administrator B.Tuguldur",
    contactSection4Email: "tuguldur.b@med.gov.mn",
    contactSection4Href: "mailto:tuguldur.b@med.gov.mn",
    contactSection4Phone: " +976-51-264182",
    contactSection4Fax: " +976-263333",

    faq1question: "1. Who has access to the SIRM system?",
    faq1answer:
      "You can contact this SIRM system to file a complaint regarding foreign investment and get a prompt response.",
    faq2question: "2. How long will the system take to resolve our grievances?",
    faq2answer:
      "1. Receive, study and classify - 3 days, 2. Receive comments from relevant organizations - 10 days, 3. Submit proposals to the decision-making body - 10 days, 4. Respond to complainants - 2 days, 5. SIRM system is your Complaints shall be resolved within 25 working days and in case of resolving the complaint at the Board meeting, this period shall be extended until the Board meets and makes a decision.",
    faq3question: "3. How does the system benefit us?",
    faq3answer:
      "By filing a complaint with the SIRM system, you will be able to monitor the progress of your complaint from time to time, eliminate delays in the transmission of official letters between government agencies, and resolve your grievances quickly.",
    faq4question: "4. Is it possible to file a paper complaint?",
    faq4answer:
      "It is possible to file a complaint in paper form, but if you file a complaint through the SIRM system, your complaint will be registered in the system and will be the basis for changing the legal regulations that give rise to the complaint in the future.",
    faq5question: "5. How do you receive a response to your complaint?",
    faq5answer:
      "The grievance will be notified to the official email address registered in your system. Information about grievance proceedings will also be sent to your email from time to time.",

    video1Title: "Introduction to PPCC",
    video1Desc: "How does PPCC work from this presentation?",
    video2Title: "Introduction to SIRM system",
    video2Desc: "How will SIRM system handle your complaint?",

    menu: "Menu",
    list: [
      {
        keyword: "about, tuhai, nda, ipc, council",
        name: "About Us",
        link: "/page/aboutUs",
      },
      {
        keyword: "head, government, director",
        name: "Head of the Council's Message",
        link: "/page/headMessage",
      },
      { keyword: "office, secretary", name: "Office", link: "/page/office" },
      {
        keyword: "annual report, pdf, tailan",
        name: "Annual Report",
        link: "/content/annualReport",
      },
      {
        keyword: "contact us, phone, number, mail, email",
        name: "Contact Us",
        link: "/contact",
      },
      {
        keyword: "how can the sirm help?, what is, sirm, grievance",
        name: "How can the SIRM help?",
        link: "/page/help",
      },
      {
        keyword: "checklist for a grievance, guidance",
        name: "Checklist for a Grievance",
        link: "/page/greivanceChecklist",
      },
      {
        keyword: "how do we deal with grievance?",
        name: "How do we deal with Grievance?",
        link: "/page/howDoWeDeal",
      },
      {
        keyword:
          "make a grievance, submit, register, login, feedback, grievance",
        name: "Make a Grievance",
        link: process.env.REACT_APP_SIRM_URI,
      },
      {
        keyword: "knowledge base, cases, public library",
        name: "Knowledge Base",
        link: "/knowledgeBase",
      },
      {
        keyword: "ipc recommendations",
        name: "IPC Recommendations",
        link: "/content/recommendation",
      },
      {
        keyword: "guide, tutorial, help, step by step",
        name: "Guide",
        link: "/page/guide",
      },
      {
        keyword: "news, media, newspaper",
        name: "News",
        link: "/content/news",
      },
      {
        keyword: "ppcc about us",
        name: "PPCC About Us",
        link: "/page/PPCCAboutUs",
      },
      { keyword: "ppcc news", name: "PPCC News", link: "/content/PPCCNews" },
      {
        keyword: "ppcc meetings, pdf",
        name: "PPCC Meetings",
        link: "/content/PPCCMeetings",
      },
      {
        keyword: "ppcc reports, pdf",
        name: "PPCC Reports",
        link: "/content/PPCCReports",
      },
    ],
  },
  MN: {
    // Menus
    home: "Нүүр хуудас",
    aboutUs: "Бидний тухай",
    headMessage: "Даргын мэндчилгээ",
    office: "Ажлын алба",
    annualReport: "Үйл ажиллагааны тайлан",
    contactUs: "Холбоо барих",
    onlineApplication: "Цахим гомдол",
    onlineApplicationDesc:
      "Гадаадын хөрөнгө оруулагчийн гомдол мэдүүлэх систем",
    help: "SIRM танд хэрхэн туслах вэ?",
    greivanceChecklist: "Гомдол мэдүүлэх заавар",
    howDoWeDeal: "Таны гомдлыг хэрхэн шийдвэрлэх вэ?",
    makeGreviance: "Гомдол гаргах",
    resolvedCases: "Хурлын шийдвэрүүд",
    knowledgeBase: "Шийдвэрлэсэн гомдлууд",
    knowledgeBaseDesc: "Шийдвэрлэгдсэн гомдлын кэйсүүд",
    regulatoryInformation: "Эрх зүйн хөтөч",
    regulatoryInformationDesc:
      "Хөрөнгө оруулалттай холбоотой хууль, эрх зүйн акт",
    guide: "Хөтөч",
    governmentLegislation: "ХОЭАХЗ-ийн зөвлөмж",
    PPCC: "ТХХЗХ",
    PPCCAboutUs: "ТХХЗХ Тухай",
    PPCCNews: "ТХХЗХ Мэдээ",
    PPCCMeetings: "ТХХЗХ Уулзалт",
    PPCCReports: "ТХХЗХ Тайлан",
    notice: "Мэдээ",
    usefulSites: "Хэрэгцээтэй сайтууд",
    customerService: "Хэрэглэгчтэй холбогдох төв",
    privacyPolicy: "Мэдээллийн нууцлал",
    sitemap: "Сайтын бүтэц",
    recommendation: "ХОЭАХЗ-ийн зөвлөмж",
    meetings: "Хурал",
    reports: "Тайлан",
    information: "Мэдээлэл",

    // Buttons
    create: "Үүсгэх",
    update: "Шинэчлэх",
    login: "Нэвтрэх",
    formSubmit: "Илгээх",
    continue: "Үргэлжлүүлэх",
    cancel: "Болих",
    delete: "Устгах",
    loading: "Уншиж байна",
    more: "Дэлгэрэнгүй",
    previous: "Өмнөх",
    next: "Дараах",

    // Register
    register: "Бүртгүүлэх",
    email: "И-мэйл",
    password: "Нууц үг",
    passwordRepeat: "Нууц үг давтах",

    // Labels
    labelEmail: "И-Мэйл хаяг",
    labelPassword: "Нууц үг",

    // Tooltips
    changeLocale: "Хэл солих",
    processEdit: "Өөрчлөлт оруулах",
    processNew: "Шинээр процесс үүсгэх",
    formEdit: "Формын мэдээлэл засварлах",
    formNew: "Шинээр форм үүсгэх",
    formFieldEdit: "Формын талбар засварлах",
    formFieldNew: "Формд шинээр талбар нэмэх",
    fieldEdit: "Талбарын мэдээлэл засварлах",
    fieldNew: "Шинээр талбар үүсгэх",
    backToProcessList: "Процессын жагсаалт руу буцах",
    moveUp: "Дээш зөөх",
    moveDown: "Доош зөөх",
    saveOrder: "Дарааллыг хадгалах",
    save: "Хадгалах",

    // Notifications
    updateSuccessful: "Амжилттай шинэчлэгдлээ",
    insertSuccessful: "Амжилттай үүслээ",

    // Errors
    errorUnauthorized: "И-Мэйл эсвэл нууц үг буруу байна",
    errorInternal: "Алдаа гарлаа",
    notFound: "Хуудас олдсонгүй!",
    newsNotFound: "Мэдээ олдсонгүй!",
    contentNotFound: "Агуулга олдсонгүй",

    // Sidebar
    sidebarGrievances: "Гомдлууд",
    sidebarProcesses: "Процессууд",
    sidebarReferences: "Сонгох утга",

    // References
    references: "Сонгох утга",
    referenceNew: "Сонгох утга үүсгэх",
    referenceEdit: "Сонгох утга засварлах",
    referenceName: "Нэр",
    referenceLabel: "Харагдах нэр",
    referenceValue: "Утга",
    referenceKey: "Reference Key",
    referenceAddValue: "Шинэ утга нэмэх",
    referenceEditValue: "Утга засварлах",
    referenceDeleteValue: "Устгах",
    referenceDeleteValueConfirm: "Та устгахдаа итгэлтэй байна уу?",
    referenceSubLevel: "Дэд утга",
    referenceSave: "Хадгалах",

    // Builder
    // tTabs
    processEditor: "Процесс",
    processDiagram: "Процессын зураг",
    formEditor: "Форм",
    formFieldEditor: "Формын талбарууд",
    formGroupFieldEditor: "Групп талбарууд",
    formPreviewer: "Харагдац",
    fieldEditor: "Процессын талбарууд",

    // Process fields
    processKey: "Камунда Процесс",
    processName: "Нэр",
    processStartable: "Эхлүүлэх боломжтой процесс эсэх?",
    processPublishedVer: "Ажиллаж буй хувилбар",

    // Process version fields
    procVerVersion: "Хувилбар",
    procVerCreated: "Үүсгэсэн огноо",
    procVerDescription: "Тайлбар",
    procVerDiagram: "Диаграм",
    procVerViewDiagram: "Диаграм харах",
    procVerIsPublished: "Нийтлэгдсэн?",
    procVerPublish: "Нийтлэх",
    procVerPublishNew: "Шинэ хувилбар нийтлэх",
    procVerPublishExisting: "Одоогийн хувилбарыг нийтлэх",

    // form fields
    formKey: "Камунда Форм",
    formName: "Нэр",
    formDescription: "Тайлбар",
    formDueTimer: "Camunda дуусах хугацаа",

    // Field fields
    fieldLabel: "Талбарын нэр",
    fieldType: "Төрөл",
    fieldReadOnly: "Өөрчлөлт оруулах боломжгүй",
    fieldDefaultVal: "Анхны утга",
    fieldCamVariable: "Камунда талбар",

    // Form field fields
    formFieldFieldId: "Талбар",
    formFieldReadOnly: "Өөрчлөлт оруулах боломжгүй",
    formFieldRequired: "Заавал бөглөх",
    formFieldLabel: "Талбарын нэр",
    formFieldType: "Төрөл",
    formFieldSize: "Талбарын өргөн",
    formFieldNewLine: "Шинэ мөрнөөс эхлэх",

    // Other - TODO
    userGrievanceTasks: "Шийдвэрлэх гомдлууд",
    taskListEmpty: "Гомдол алга",
    processes: "Процессууд",
    formList: "Формууд",
    fieldList: "Талбарууд",
    select: "Сонго",
    processNotDeployed: "Процесс хараахан идэвхжээгүй байна",
    createdAt: "Үүссэн Огноо",
    choose: "Сонго",
    yes: "Тийм",
    no: "Үгүй",
    na: "N/A",
    actions: "Үйлдлүүд",
    confirmDialogTitle: "Та энэ үйлдлийг хийхэд итгэлтэй байна уу?",

    // News
    newsPageTitle: "Мэдээ, мэдээлэл",
    news: "Мэдээ",
    newsNew: "Шинэ Мэдээ",
    newsEdit: "Мэдээ Засах",
    newsTitle: "Гарчиг",
    newsKey: "Мэдээний ID",
    publish: "Publish",
    unpublish: "Драфт болгох",

    // KnowledgeBase
    knowledgeCategory: "Шийдвэрлэсэн гомдлын ангилал",
    relatedLaws: "Хууль эрх зүйн зохицуулалт",
    relatedOrgs: "Холбогдох төрийн байгууллага",
    grievanceDate: "Гомдол гаргасан огноо",
    resolutionDate: "Шийдвэрлэгдсэн огноо",
    shortGrievanceDesc: "Гомдлын товч агуулга",
    resoulutionDesc: "Шийдвэрлэлт",
    knowledgeBaseEmpty: "Одоогоор шийдвэрлэсэн гомдол байхгүй байна.",
    seeList: "Жагсаалт Харах",

    // Newsletter
    subscribe: "Бүртгүүлэх",
    youremail: "Таны имэйл...",
    newsletterTitle: "Бидний мэдээллын товхимолд бүртгүүлээрэй.",
    newsletterSuccess: "Та амжилттай бүртгүүллээ",
    newsletterHintText:
      "Бидний мэдээллийн товхимолд бүртгүүлэн имэйлээр хүлээн аваарай.",
    enterValidEmail: "Та имэйл хаягаа шалгаад, зөв оруулна уу",

    faq: "Түгээмэл асуулт, хариултууд",
    faqDesctiption: "Хамгийн их асуугдсан 5 асуулт",
    faqHowUrl: "/img/how-mn.png",

    // Footer
    addressTitle: "Хаяг",
    addressLine:
      "Улаанбаатар хот, Чингэлтэй дүүрэг, Нэгдсэн Үндэстний Гудамж 5/1, Засгийн газрын ll байр, Үндэсний хөгжлийн газар",
    phoneTitle: "Утас",
    emailTitle: "Имэйл",
    socialTitle: "Сошиал Хаяг",
    copyrightLine: "Хөрөнгө Оруулагчдын Эрх Ашгийг Хамгаалах Зөвлөл ©",

    //Video Intro
    video: "Видео танилцуулга",
    videoDescription: "Бидний тухай хамгийн товчоор",

    //Security
    serurity: "Нууцлалын баталгаа",
    securityDescription:
      "ХОЭАХЗ нь иргэн болон хуулийн этгээдийн мэдээллийг гуравдагч этгээдэд ашиглуулахгүй, түгээхгүй бөгөөд гомдлыг шийдвэрлэх хэрэгцээнээс гадуур аливаа зорилгоор ашиглахгүй болно.",
    guideButton: "Хэрэглэгчийн гарын авлага",

    onlineApplicationDescription:
      "Хөрөнгө оруулагч та энд дарж гомдол гаргах системд холбогдоно уу.",
    knowledgeBaseDescription:
      "Бидэнд хандсан гомдлууд болон тэдгээрийн шийдвэрлэлттэй энд дарж танилцана уу.",
    regulatoryInformationDescription:
      "Хөрөнгө оруулалтын хууль, ОУ-ын гэрээ, конвенциудтай энд дарж танилцана уу.",

    bannerTitle: "Бид танд хэрхэн туслах вэ?",
    bannerDesc:
      "Хөрөнгө оруулагч гомдлынхоо шийдвэрлэлтэд хяналт тавих боломжийг бүрдүүлсэн, төрийн байгууллага хоорондын мэдээлэл солилцоог хялбарчилсан, хөрөнгө оруулагчийн систем",
    searchButton: "Мэдээлэл хайх...",

    processTitle: "Бид хэрхэн таны гомдлыг шийдвэрлэдэг вэ?",
    processDesc: "Гомдлыг төрлөөр нь ангилан харуулав",
    processChoice1: "Тусгай зөвшөөрөл",
    processChoice2: "Хяналт шалгалт",
    processChoice3: "Татвар",
    processChoice4: "Хөдөлмөр эрхлэлт",
    processChoice5: "Виз",
    processChoice6: "Гэрээний биелэлт",
    processChoice7: "Өмч хураан авалт",
    processChoice8: "Валютын шилжүүлэг",
    processChoice9: "Ялгаварлан гадуурхалт",
    processChoice10: "Бусад",

    contactSection1:
      "Эдийн засаг, хөгжлийн яам, Улаанбаатар хот, Чингэлтэй дүүрэг, Нэгдсэн Үндэстний Гудамж 5/1 Засгийн газрын II байр",
    contactSection2:
      "Хөрөнгө оруулагчдын эрх ашгийг хамгаалах зөвлөлийн ажлын албаны цахим шуудан",
    contactSection2Email: "contact@ipc.gov.mn",
    contactSection2Href: "mailto:contact@ipc.gov.mn",
    contactSection3:
      "Хөрөнгө оруулагчдын эрх ашгийг хамгаалах зөвлөлийн ажлын албаны өргөдөл гомдол хариуцсан дарга Г.Насанбуян",
    contactSection3Email: "nasanbuyan.g@med.gov.mn",
    contactSection3Href: "mailto:nasanbuyan.g@med.gov.mn",
    contactSection3Phone: " +976-51-264182",
    contactSection4:
      "Хөрөнгө оруулагчдын эрх ашгийг хамгаалах зөвлөлийн ажлын албаны өргөдөл гомдолын системын админ Б.Төгөлдөр",
    contactSection4Email: "tuguldur.b@med.gov.mn",
    contactSection4Href: "mailto:tuguldur.b@med.gov.mn",
    contactSection4Phone: " +976-51-264182",
    contactSection4Fax: " +976-263333",

    faq1question: "1. SIRM системд хэн хандах эрхтэй вэ?",
    faq1answer:
      "Хөрөнгө оруулагч та өөрөө эсвэл өөрийн итгэмжлэгдсэн төлөөлөгчөөрөө дамжуулан системд нэвтрэх боломжтой.",
    faq2question: "2. Бидний гомдлыг систем ямар хугацаанд шийдвэрлэх вэ?",
    faq2answer:
      "1. Хүлээн авч, судалж, ангилах-3 хоног, 2. Холбогдох байгууллагаас санал авах-10 хоног, 3. Шийдвэрлэх чиг үүрэг бүхий байгууллагад саналыг хүргүүлэх-10 хоног, 4. Гомдол гаргагчид хариу өгөх-2 хоног, 5. SIRM систем таны гомдлыг ажлын 25 хоногт шийдвэрлэх ба гомдлыг Зөвлөлийн хурлаар шийдвэрлэх тохиолдолд уг хугацаа Зөвлөл хуралдаж шийдвэр гаргах хүртэл сунгагдана. ",
    faq3question: "3. Систем бидэнд ямар ашигтай вэ?",
    faq3answer:
      "Та SIRM системд хандан гомдол гаргаснаар өөрийн гомдлыг аль үе шатанд явж байгааг тухай бүр хянах боломжтой бөгөөд төрийн байгууллага хоорондын албан тоот дамжуулах хугацаа алдагдлыг байхгүй болгож, гомдлоо шуурхай шийдвэрлүүлэх боломжоор хангагдана. ",
    faq4question: "4. Цаасан хэлбэрээр гомдол гаргах боломжтой юу?",
    faq4answer:
      "Цаасан хэлбэрээр гомдол гаргах боломжтой ч та SIRM системээр дамжуулж гомдлоо гаргаснаар таны гомдол системд бүртгэгдэж, ирээдүйд гомдлыг үүсгэж буй хууль, эрх зүйн зохицуулалтыг өөрчлөх үндэслэл болох юм.",
    faq5question: "5. Гомдлынхоо хариуг хэрхэн хүлээж авах вэ?",
    faq5answer:
      "Таны системд бүртгүүлсэн албан ёсны имэйл хаягаар гомдлын шийдвэрлэлтийг мэдэгдэх болно. Мөн гомдолтой холбоотой үйл ажиллагааны талаарх мэдээлэл таны имэйлд тухай бүр очих болно.",

    video1Title: "ХОЭАХЗ-ийн танилцуулга",
    video1Desc: "Та энэ танилцуулгаас ХОЭАХЗ хэрхэн ажилладаг вэ?",
    video2Title: "SIRM систем танилцуулга",
    video2Desc: "SIRM таны гомдлыг систем хэрхэн шийдвэрлэх вэ?",

    menu: "Цэс",
    list: [
      {
        keyword:
          "бидний тухай, үхг, үндэсний хөгжлийн газар, нэг цонхны үйлчилгээ, хэн",
        name: "Бидний тухай",
        link: "/page/aboutUs",
      },
      {
        keyword: "дарга, мэндчилгээ, даргын",
        name: "Даргын мэндчилгээ",
        link: "/page/headMessage",
      },
      {
        keyword: "ажлын алба, үйлчилгээ",
        name: "Ажлын алба",
        link: "/page/office",
      },
      {
        keyword: "үйл ажиллагааны тайлан, дүгнэлт, явц, pdf",
        name: "Үйл ажиллагааны тайлан",
        link: "/content/annualReport",
      },
      {
        keyword: "холбоо барих, утас, имэйл, хаяг",
        name: "Холбоо барих",
        link: "/contact",
      },
      {
        keyword: "sirm танд хэрхэн туслах вэ?, тусламж, асуулт",
        name: "SIRM танд хэрхэн туслах вэ?",
        link: "/page/help",
      },
      {
        keyword: "гомдол гаргахад шаардлагатай жагсаалт",
        name: "Гомдол гаргахад шаардлагатай жагсаалт",
        link: "/page/greivanceChecklist",
      },
      {
        keyword: "таны гомдлыг хэрхэн шийдвэрлэх вэ?",
        name: "Таны гомдлыг хэрхэн шийдвэрлэх вэ?",
        link: "/page/howDoWeDeal",
      },
      {
        keyword: "гомдол гаргах, бүртгэх, бүртгүүлэх, нэвтрэх",
        name: "Гомдол гаргах",
        link: "/sirm/login",
      },
      {
        keyword: "шийдвэрлэсэн гомдлууд, кэйс, шийдвэрүүд",
        name: "Шийдвэрлэсэн гомдлууд",
        link: "/knowledgeBase",
      },
      {
        keyword: "хоэахз-ийн зөвлөмж",
        name: "ХОЭАХЗ-ийн зөвлөмж",
        link: "/content/recommendation",
      },
      {
        keyword: "хөтөч, заавар, гарын авлага",
        name: "Хөтөч",
        link: "/page/guide",
      },
      { keyword: "мэдээ, мэдээлэл", name: "Мэдээ", link: "/content/news" },
      {
        keyword: "тххзх тухай, төр хувийн хэвшлийн зөвлөлдөх хороо",
        name: "ТХХЗХ Тухай",
        link: "/page/PPCCAboutUs",
      },
      {
        keyword: "тххзх мэдээ",
        name: "ТХХЗХ Мэдээ",
        link: "/content/PPCCNews",
      },
      {
        keyword: "тххзх уулзалт",
        name: "ТХХЗХ Уулзалт",
        link: "/content/PPCCMeetings",
      },
      {
        keyword: "тххзх тайлан",
        name: "ТХХЗХ Тайлан",
        link: "/content/PPCCReports",
      },
    ],
  },
};

export default new LocalizedStrings(LocalStrings);
