import React from "react";
import strings from "../locale/strings"

class Video extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false
    }  
  }

  render() {
    return (
      <div className="uk-section section-videos">
        <div className="uk-container uk-container-small">
          <h2 className="uk-h1 uk-text-center">{strings.video}</h2>
          <p className="uk-text-lead uk-text-center">
            {strings.videoDescription}
          </p>
          <div
            className="uk-child-width-1-2@m uk-grid-match uk-text-center uk-margin-medium-top"
            data-uk-grid
            data-uk-lightbox
          >
            <div>
              <div className="uk-card uk-card-default uk-box-shadow-small uk-box-shadow-hover-medium uk-card-body uk-inline border-radius-large border-xlight">
                <a
                  className="uk-position-cover"
                  href="https://www.youtube.com/watch?v=MY04Uxi1vno"
                  data-caption=""
                > </a>
                <span data-uk-icon="icon: play-circle; ratio: 3.4" className="data-uk-icon" />
                <h3 className="uk-card-title uk-margin">
                  {strings.video1Title}
                </h3>
                <p>{strings.video1Desc}</p>
              </div>
            </div>
            <div>
              <div className="uk-card uk-card-default uk-box-shadow-small uk-box-shadow-hover-medium uk-card-body uk-inline border-radius-large border-xlight">
                <a
                  className="uk-position-cover"
                  href="https://www.youtube.com/watch?v=ZDwNPVlf3lk"
                  data-caption=""
                > </a>
                <span data-uk-icon="icon: play-circle; ratio: 3.4" className="data-uk-icon" />
                <h3 className="uk-card-title uk-margin">
                {strings.video2Title}
                </h3>
                <p>{strings.video2Desc}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    );
  }
}

export default Video;
