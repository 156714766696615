import React from 'react'
import strings from '../../locale/strings'

class ContactPage extends React.Component {
  
  componentDidMount () {
    window.scrollTo(0, 0)
    document.body.scrollTop = 0
  }

  render () {
    return (
      <div className="uk-section">
        <div className="uk-container uk-container-small">
          <h2 className="uk-h1 uk-text-center">{strings.contactUs}</h2>
          <br/>
          <p className="uk-text-left uk-text-lead">
          {strings.contactSection1}
          </p>
          <br/>
          <p className="uk-text-left uk-text-lead">
            {strings.contactSection2}<br/>
            <a href={strings.contactSection2Href}><span  style={{color:'#163885'}} data-uk-icon="icon: mail; ratio: 1.2"></span> {strings.contactSection2Email}</a>
          </p>
          <br/>
          <p className="uk-text-left uk-text-lead">
            {strings.contactSection3}<br/>
            <a href={strings.contactSection3Href}><span  style={{color:'#163885'}} data-uk-icon="icon: mail; ratio: 1.2"></span> {strings.contactSection3Email}</a>
          </p>
          <p className="uk-text-left uk-text-lead">
            <span  style={{color:'#163885'}} data-uk-icon="icon: receiver; ratio: 1.2"></span>{strings.contactSection3Phone}<br/>
          </p> 
          <br/>
          <p className="uk-text-left uk-text-lead">
            {strings.contactSection4}<br/>
            <a href={strings.contactSection4Href}><span  style={{color:'#163885'}} data-uk-icon="icon: mail; ratio: 1.2"></span> {strings.contactSection4Email}</a>
          </p>
          <p className="uk-text-left uk-text-lead">
            <span style={{color:'#163885'}} data-uk-icon="icon: receiver; ratio: 1.2"></span> {strings.contactSection4Phone}<br/>
          </p>                                          
          <br/>
          <p className="uk-text-left uk-text-lead">
          <span  style={{color:'#163885'}} data-uk-icon="icon: print; ratio: 1.2"></span>{strings.contactSection4Fax}<br/>
          </p> 
          <br/>
          <p className="uk-text-left uk-text-lead">
            <img alt="map" src="img/map.png"/>
          </p>                                                     
        </div>
      </div>
    )
  }
}

export default ContactPage
