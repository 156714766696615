import React from 'react'
import axios from 'axios'
import { NavLink } from 'react-router-dom'
import { combineURLs } from '../../misc/utils'

// @material-ui/core components 
import withStyles from '@material-ui/core/styles/withStyles'
import CircularProgress from '@material-ui/core/CircularProgress'

// core components
import GridContainer from '../../components/Grid/GridContainer.jsx'
import GridItem from '../../components/Grid/GridItem.jsx'
import Table from '../../components/Table/Table.jsx'
// import Paginations from 'components/Pagination/Pagination.jsx'

import style from '../../assets/jss/material-kit-pro-react/views/componentsSections/contentAreas.jsx'
import strings from '../../locale/strings'
import moment from 'moment'

class CasesTable extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      knowledgeBases: [],
      loading: false,
      loaded: false
    }
  }

  componentDidMount = () => {
    this.loadData()
  }

  loadData = async () => {
    if (this.state.loading) {
      return
    }
    this.setState({
      loading: true
    })
    try {
      const knowledgeBasesRes = await axios.get(
        combineURLs(process.env.REACT_APP_API_URI, 'knowledge-base?refId=' + this.props.type.refId + '&language=' + this.props.language)
      )
      console.log('knowledgeBasesRes', knowledgeBasesRes)

      this.setState({
        knowledgeBases: knowledgeBasesRes.data.knowledgeBases,
        loaded: true,
        loading: false
      })
    } catch (error) {
      this.setState({
        loadFailed: true,
        loading: false
      })
    }
  }

  renderCases = () => {
    const { classes, tabId, type } = this.props
    let { knowledgeBases } = this.state
    return (
      knowledgeBases.map((kb, i) => {
        return (
          [
            i+1,
            <NavLink to={'/knowledgeBase/' + tabId + '/' + kb.uuid}
              className="title-a">
              {kb.title}
            </NavLink>,
            moment(kb.grievanceDate).format('YYYY/MM/DD'),
            moment(kb.resolutionDate).format('YYYY/MM/DD')
            // type.resolutionDate
          ]
        )
      })
    )
  }

  render () {
    const { classes, type, ...rest } = this.props
    let { loading, knowledgeBases } = this.state
    if (loading) {
      return (
        <div className={classes.loaderContainer}>
          <CircularProgress className={classes.loader} size={60} thickness={5} />
        </div>
      )
    } else {
      return (
        <div {...rest} className='cd-section' id='contentAreas'>
          {/* <h2>{type.value}</h2> */}
          <div id='tables'>
            <GridContainer>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                className={`${classes.mrAuto} ${classes.mlAuto}`}
              >
                {
                  knowledgeBases.length !== 0
                    ? <Table
                      tableHead={[
                        '#', strings.newsTitle, strings.grievanceDate, strings.resolutionDate
                      ]}
                      tableData={this.renderCases()}
                      tableHeaderColor={'primary'}
                      customCellClasses={[
                        classes.textCenter,
                        classes.textRight,
                        classes.textRight
                      ]}
                      customHeadCellClasses={[
                        classes.textCenter,
                        classes.textRight,
                        classes.textRight,
                        classes.thBackground
                      ]}
                    />
                    : <div>{strings.knowledgeBaseEmpty}</div>
                }
              </GridItem>
            </GridContainer>
          </div>
        </div>
      )
    }
  }
}

export default withStyles(style)(CasesTable)
