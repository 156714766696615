import React from "react";
import strings from "../locale/strings"
import FooterLinks from "./FooterLinks.jsx";
import moment from "moment";

class Footer extends React.Component {
  render() {
    return (
      <footer className="uk-section uk-text-center uk-text-muted">
        <div className="uk-container uk-container-small">
          <div>
            <FooterLinks></FooterLinks>
          </div>
          <div className="uk-margin-medium">
            <div
              data-uk-grid
              className="uk-child-width-auto uk-grid-small uk-flex-center"
            >
              <div className="uk-first-column">
                <a
                  href="https://twitter.com/ndagovMN"
                  data-uk-icon="icon: twitter"
                  className="uk-icon-link uk-icon"
                  target="_blank"
                  rel="noopener noreferrer"
                > </a>
              </div>
              <div>
                <a
                  href="https://www.facebook.com/ndagovMN/"
                  data-uk-icon="icon: facebook"
                  className="uk-icon-link uk-icon"
                  target="_blank"
                  rel="noopener noreferrer"
                > </a>
              </div>
              <div>
                <a
                  href="https://www.youtube.com/channel/UCwa3Yh0SrzZlUR9IzkJHo5Q"
                  data-uk-icon="icon: youtube"
                  className="uk-icon-link uk-icon"
                  target="_blank"
                  rel="noopener noreferrer"
                > </a>
              </div>
            </div>
          </div>
          <div className="uk-margin-medium uk-text-small copyright link-secondary">
            {strings.copyrightLine} {moment(new Date()).format("YYYY")}
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
