import React from 'react'
import PropTypes from 'prop-types'
import Validator from './Validator'
import CustomInput from '../CustomInput/CustomInput'

function ValidInput ({ allowEmpty, notEmpty, maxLength, minLength, length, regex, phone, email, url, number, maxValue, minValue, inValue, equalTo, notEqualTo, money, inputProps, ...props }) {
  const validationProps = { allowEmpty, notEmpty, maxLength, minLength, length, regex, phone, email, url, number, maxValue, minValue, inValue, equalTo, notEqualTo, money }
  function onChange (event) {
    event.preventDefault()
    const { name, value } = event.target
    const result = Validator.validate(validationProps, value)
    if (result !== Validator.RESULT.UNACCEPTED) {
      props.onChange(name, value, result)
    }
  }
  return (
    <CustomInput {...props} inputProps={{ ...inputProps, onChange: onChange }} />
  )
}

ValidInput.propTypes = {
  allowEmpty: PropTypes.bool,
  notEmpty: PropTypes.bool,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  length: PropTypes.number,
  regex: PropTypes.instanceOf(RegExp),
  phone: PropTypes.bool,
  email: PropTypes.bool,
  url: PropTypes.bool,
  number: PropTypes.bool,
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  inValue: PropTypes.array,
  equalTo: PropTypes.any,
  notEqualTo: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  money: PropTypes.bool
}

export default ValidInput
