import React, { useReducer, useEffect, useContext } from 'react'
import axios from 'axios'
import { combineURLs } from '../misc/utils'
import { getLocale } from '../misc/localeManager'
import AppProvider from '../misc/providers'
import strings from "../locale/strings"
import { NavLink } from 'react-router-dom'

const KnowledgeBase = ({ classes, ...props }) => {
  
  const context = useContext(AppProvider)

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      grievanceTypes: [],
      loading: false,
      loaded: false,
      tabId: 0
    }
  )

  const loadData = async () => {
    if (state.loading) {
      return
    }
    setState({
      loading: true
    })
    try {
      const { locale, nextLocale } = getLocale()
      let language = locale === 'MN' ? 'mn' : 'en'

      const grievanceTypesRes = await axios.get(
        combineURLs(process.env.REACT_APP_API_URI, 'knowledge-base/grievance-types?language=' + language)
      )
      console.log('grievanceTypesRes', grievanceTypesRes)

      setState({
        grievanceTypes: grievanceTypesRes.data.grievanceTypes,
        loaded: true,
        loading: false,
        tabId: 0
      })
    } catch (error) {
      setState({
        loadFailed: true,
        loading: false,
        tabId: 0
      })
    }
  }

  const renderTabs = () => {
    // const { match: { params } } = props
    // const { uuid, tabId } = params
    let { grievanceTypes } = state
    let language = context.locale === 'MN' ? 'mn' : 'en'

    return (
      grievanceTypes.map((type, i) => {
        return (
          <li>
            <NavLink to={'/knowledgeBase/'+i}
              className="uk-box-shadow-hover-small">
              {type.value}
            </NavLink>
          </li>
        )
      })
    )
  }  

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { loadData() }, [context.locale])

  return (
    <div className="uk-section section-featured">
      <div className="uk-container uk-container-small">
        <h2 className="uk-h1 uk-text-center">
          {strings.processTitle}
        </h2>
        <p className="uk-text-center uk-text-lead">
          {strings.processDesc}
        </p>
        <ul className="uk-list uk-list-large uk-margin-medium-top">

          {renderTabs()}

        </ul>
      </div>
    </div>
  );
}

export default KnowledgeBase;
