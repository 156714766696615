import React from "react";
import strings from "../locale/strings"

class HomePage extends React.Component {
  render() {
    return (
      <div className="uk-section">
        <div className="uk-container">
          <div
            className="uk-child-width-1-3@m uk-grid-match uk-text-center uk-margin-medium-top"
            data-uk-grid
          >
            <div>
              <div className="uk-card uk-card-default uk-box-shadow-medium uk-card-hover uk-card-body uk-inline border-radius-large border-xlight">
                <a className="uk-position-cover" href="/sirm/login"> </a>
                <span data-uk-icon="icon: cog; ratio: 3.4" className="data-uk-icon"/>
                <h3 className="uk-card-title uk-margin">{strings.onlineApplication}</h3>
                <p>
                  {strings.onlineApplicationDescription}
                </p>
              </div>
            </div>
            <div>
              <div className="uk-card uk-card-default uk-box-shadow-medium uk-card-hover uk-card-body uk-inline border-radius-large border-xlight">
                <a className="uk-position-cover" href="/knowledgeBase"> </a>
                <span data-uk-icon="icon: settings; ratio: 3.4" className="data-uk-icon"/>
                <h3 className="uk-card-title uk-margin">
                  {strings.knowledgeBase}
                </h3>
                <p>
                  {strings.knowledgeBaseDescription}
                </p>
              </div>
            </div>
            <div>
              <div className="uk-card uk-card-default uk-box-shadow-medium uk-card-hover uk-card-body uk-inline border-radius-large border-xlight">
                <a className="uk-position-cover" href="/page/guide"> </a>
                <span data-uk-icon="icon: code; ratio: 3.4" className="data-uk-icon"/>
                <h3 className="uk-card-title uk-margin">{strings.regulatoryInformation}</h3>
                <p>
                  {strings.regulatoryInformationDescription}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HomePage;
