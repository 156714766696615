import { container, title } from '../../../material-kit-pro-react.jsx'

const pillsStyle = {
  section: {
    padding: '20px 0'
  },
  container,
  title: {
    ...title,
    marginTop: '30px',
    minHeight: '32px',
    textDecoration: 'none'
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '50vh',
    padding: '100px'
  }
}

export default pillsStyle
