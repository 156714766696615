import React, { useReducer, useEffect, useContext } from 'react'
import axios from 'axios'
import { combineURLs } from '../../misc/utils'

// nodejs library that concatenates classes
// import classNames from 'classnames'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import CircularProgress from '@material-ui/core/CircularProgress'

// core components
import GridContainer from '../../components/Grid/GridContainer.jsx'
import GridItem from '../../components/Grid/GridItem.jsx'
// import Card from 'components/Card/Card.jsx'
// import CardBody from 'components/Card/CardBody.jsx'

// import blogsStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/pres.jsx'
import presentationStyle from '../../assets/jss/material-kit-pro-react/views/presentationStyle.jsx'

// draftjs components
import { convertFromRaw } from 'draft-js'
//import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { stateToHTML } from 'draft-js-export-html'

// import AppProvider from '../../misc/providers'
import strings from '../../locale/strings'
import { getLocale } from '../../misc/localeManager'

const Page = ({ classes, ...props }) => {
  // const context = useContext(AppProvider)
  const { locale, nextLocale } = getLocale()

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      pageKey: null,
      pageContent: null,
      coverImage: null,
      loading: false,
      loaded: false,
      notFound: true
    }
  )

  const convertContentFromJSONToHTML = (text) => {
    let options = {
      entityStyleFn: (entity) => {
        const entityType = entity.get('type').toLowerCase()
        if (entityType === 'image') {
          const data = entity.getData()
          return {
            element: 'img',
            attributes: {
              src: data.src
            },
            style: {
              maxWidth: '90%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
              // height: auto;
            }
          }
        }
      }
    }
    return stateToHTML(convertFromRaw(JSON.parse(text)), options)
  }

  const fetchData = async () => {
    console.log('dada', locale)
    window.scrollTo(0, 0)
    document.body.scrollTop = 0
    if (state.loading) {
      return
    }

    setState({
      loading: true
    })
    try {
      let language = locale === 'MN' ? 'mn' : 'en'

      let newsRes = await axios.get(combineURLs(process.env.REACT_APP_API_URI, '/news/' + props.match.params.pageKey + '?language=' + language))

      console.log('newsRes', newsRes)

      setState({
        pageContent: newsRes.data.news,
        coverImage: newsRes.data.news.coverImage || null,
        loading: false,
        loaded: true,
        notFound: false
      })
    } catch (error) {
      console.log(error)

      console.log('error', error.response)
      if (error.response && error.response.data && error.response.data.code === 996) {
        setState({
          notFound: true
        })
      }
      setState({
        loading: false,
        notFound: true
      })
    }
  }

  // componentWillReceiveProps (nextProps) {
  //   if (this.props !== nextProps) {
  //     this.setState({ pageKey: this.nextProps.match.params.pageKey })
  //   }
  // }

  useEffect(() => { fetchData() }, [locale])
  useEffect(() => { fetchData() }, [props.match.params.pageKey])

  if (state.loading) {
    return (
      <div className={classes.loaderContainer}>
        <CircularProgress className={classes.loader} size={60} thickness={5} />
      </div>
    )
  }
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem
            xs={1}
            sm={1}
            md={1}
          ></GridItem>
          <GridItem
            xs={10}
            sm={10}
            md={10}
            className={`${classes.mlAuto} ${classes.mrAuto}`}
          >
            {(state.notFound || state.pageContent == null)
              ? <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingTop: '200px'
                }}>
                <h4>{strings.notFound}</h4>
              </div>
              : <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                <h1 style={{ fontWeight: 'bold' }}>{state.pageContent.title}</h1>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    // justifyContent: 'center'
                    textAlign: 'justify'
                  }}>
                  {/* {
                    state.coverImage && <div>
                      <img src={state.coverImage} alt='...' style={{ maxHeight: '400px', width: 'auto', marginRight: 20 }} />
                      <div
                        className={classes.coloredShadow}
                        style={{ backgroundImage: `url(${state.coverImage})`, opacity: '1', maxHeight: '400px', width: 'auto', marginRight: 20 }}
                      />
                    </div>
                  } */}
                  {
                    state.pageContent.content !== '' &&
                    <div
                      dangerouslySetInnerHTML={{
                        __html: convertContentFromJSONToHTML(
                          state.pageContent.content
                        )
                      }}
                    />
                  }
                </div>
              </div>
            }
          </GridItem>
          <GridItem
            xs={1}
            sm={1}
            md={1}
          ></GridItem>        
        </GridContainer>
      </div>
    </div>
  )
}

export default withStyles(presentationStyle)(Page)
