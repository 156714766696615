import {
  roseColor,
  primaryColor,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  blackColor,
  whiteColor,
  grayColor,
  hexToRgb
} from '../../material-kit-pro-react.jsx'

const navPillsStyle = theme => ({
  root: {
    marginTop: '20px',
    paddingLeft: '0',
    marginBottom: '0',
    overflow: 'visible !important'
  },
  flexContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexWrap: 'wrap'
    }
  },
  displayNone: {
    display: 'none !important'
  },
  fixed: {
    overflowX: 'visible'
  },
  horizontalDisplay: {
    display: 'block'
  },
  pills: {
    float: 'left',
    position: 'relative',
    display: 'block',
    borderRadius: '30px',
    minWidth: '100px',
    textAlign: 'center',
    transition: 'all .3s',
    padding: '10px 15px',
    color: grayColor[15],
    height: 'auto',
    opacity: '1',
    maxWidth: '100%',
    margin: '0 5px',
    minHeight: 'unset'
  },
  pillsWithIcons: {
    borderRadius: '4px'
  },
  tabIcon: {
    width: '30px',
    height: '30px',
    display: 'block',
    margin: '15px 0'
  },
  horizontalPills: {
    width: '100%',
    float: 'none !important',
    '& + button': {
      margin: '10px 0'
    }
  },
  labelContainer: {
    padding: '0!important',
    color: 'inherit'
  },
  label: {
    lineHeight: '24px',
    textTransform: 'uppercase',
    fontSize: '12px',
    fontWeight: '500',
    position: 'relative',
    display: 'block',
    color: 'inherit'
  },
  contentWrapper: {
    marginTop: '20px',
    '& .react-swipeable-view-container > div > div': {
      paddingLeft: '15px',
      paddingRight: '15px'
    }
  },
  primary: {
    '&,&:hover': {
      color: whiteColor,
      backgroundColor: primaryColor[0]
    }
  },
  info: {
    '&,&:hover': {
      color: whiteColor,
      backgroundColor: infoColor[0]
    }
  },
  success: {
    '&,&:hover': {
      color: whiteColor,
      backgroundColor: successColor[0]
    }
  },
  warning: {
    '&,&:hover': {
      color: whiteColor,
      backgroundColor: warningColor[0]
    }
  },
  danger: {
    '&,&:hover': {
      color: whiteColor,
      backgroundColor: dangerColor[0]
    }
  },
  rose: {
    '&,&:hover': {
      color: whiteColor,
      backgroundColor: roseColor[0]
    }
  },
  alignCenter: {
    alignItems: 'center',
    justifyContent: 'center'
  }
})

export default navPillsStyle
