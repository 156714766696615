import React, { useReducer, useEffect, useContext } from 'react'
import axios from 'axios'
import moment from 'moment'

import { combineURLs } from '../../misc/utils'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import CircularProgress from '@material-ui/core/CircularProgress'

// @material-ui/icons
// core components
import GridContainer from '../../components/Grid/GridContainer.jsx'
import GridItem from '../../components/Grid/GridItem.jsx'
import Card from '../../components/Card/Card.jsx'
import CardBody from '../../components/Card/CardBody.jsx'
import CardHeader from '../../components/Card/CardHeader.jsx'
import Info from '../../components/Typography/Info.jsx'

import blogsStyle from '../../assets/jss/material-kit-pro-react/views/sectionsSections/blogsStyle.jsx'

import { convertFromRaw } from 'draft-js'
//import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { stateToHTML } from 'draft-js-export-html'

// import AppProvider from '../../misc/providers'
import strings from '../../locale/strings.js'
import { getLocale } from '../../misc/localeManager'

const NewsDetail = ({ classes, ...props }) => {
  // const context = useContext(AppProvider)
  const { locale, nextLocale } = getLocale()

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      news: [],
      notFound: true,
      loading: false,
      loaded: false,
      total: -1,
      currentPage: 1,
      perpage: 6,
      pages: []
    }
  )

  useEffect(() => { loadPage() }, [locale])

  const convertContentFromJSONToHTML = (text) => {
    let options = {
      entityStyleFn: (entity) => {
        const entityType = entity.get('type').toLowerCase()
        if (entityType === 'image') {
          const data = entity.getData()
          return {
            element: 'img',
            attributes: {
              src: data.src
            },
            style: {
              maxWidth: '100%'
              // height: auto;
            }
          }
        }
      }
    }
    return stateToHTML(convertFromRaw(JSON.parse(text)), options)
  }

  const loadPage = async () => {
    console.log('loadpage')
    if (state.loading) {
      return
    }

    setState({
      loading: true
    })
    try {
      let language = locale === 'MN' ? 'mn' : 'en'
      let newsRes = await axios.get(combineURLs(process.env.REACT_APP_API_URI, '/news/') +
            props.match.params.newsKey + '?language=' + language)

      console.log('newsRes', newsRes)

      setState({
        news: newsRes.data.news,
        loading: false,
        loaded: true,
        notFound: false
      })
    } catch (error) {
      console.log(error)

      console.log('error', error.response)
      if (error.response && error.response.data && error.response.data.code === 996) {
        setState({
          notFound: true
        })
      }

      setState({
        loading: false
      })
    }
  }

  let { loading, news } = state
  let coverImageUrl = combineURLs(news.coverImage)
  console.log('render', state)
  if (loading) {
    return (
      <div className={classes.loaderContainer}>
        <CircularProgress className={classes.loader} size={60} thickness={5} />
      </div>
    )
  }

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem
            xs={1}
            sm={1}
            md={1}
          ></GridItem>        
          <GridItem
            xs={10}
            sm={10}
            md={10}
            className={`${classes.mlAuto} ${classes.mrAuto}`}
          >
            {
              state.notFound
                ? <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingTop: '200px'
                  }}>
                  <h4>{strings.notFound}</h4>
                </div>

                : <Card plain blog className={classes.cardNews}>
                  {/* <CardHeader image plain className={classes.justifyCenter} style={{ display: 'flex' }}>
                    {coverImageUrl && <img src={coverImageUrl} alt='...' style={{ maxHeight: '400px', width: 'auto' }} />}
                    <div
                      className={classes.coloredShadow}
                      style={{ backgroundImage: `url(${coverImageUrl})`, opacity: '1', maxHeight: '400px', width: 'auto' }}
                    />
                  </CardHeader> */}
                  <CardBody plain className={classes.justifyCenter}>
                    <h3 style={{textAlign: 'left'}}>
                      {news.title}
                    </h3>
                    <Info>
                      <h6 className={classes.cardCategory}>{moment(news.updatedAt).format('YYYY/MM/DD HH:mm')}</h6>
                    </Info>
                    <br />
                    <br />
                    <div
                      style={{
                        textAlign: 'justify' }}
                      dangerouslySetInnerHTML={{
                        __html: convertContentFromJSONToHTML(
                          news.content
                        )
                      }}
                    />
                  </CardBody>
                </Card>
            }
          </GridItem>
          <GridItem
            xs={1}
            sm={1}
            md={1}
          ></GridItem>               
        </GridContainer>
      </div>
    </div>
  )
}

export default withStyles(blogsStyle)(NewsDetail)
