import moment from 'moment'

const RESULT = {
  VALID: 'VALID',
  INVALID: 'INVALID',
  EMPTY: 'EMPTY',
  UNACCEPTED: 'UNACCEPTED'
}

class Validator {
  static validate (props, value) {
    let ret = RESULT.VALID
    if (props.allowEmpty) {
      if (value === null || value === undefined || value === '') {
        return RESULT.EMPTY
      }
    }
    if (props.notEmpty) {
      if (value === null || value === undefined) {
        ret = RESULT.INVALID
      } else if (typeof value === 'string' || Array.isArray(value)) {
        if (value.length === 0) ret = RESULT.INVALID
      } else { throw new Error('notEmpty not supported') }
    }
    if (props.maxLength > 0) {
      if (value === null || value === undefined) {
        ret = RESULT.INVALID
      } else if (typeof value === 'string' || Array.isArray(value)) {
        if (value.length > props.maxLength) return RESULT.UNACCEPTED
      } else { throw new Error('maxLength not supported') }
    }
    if (props.minLength > 0) {
      if (value === null || value === undefined) {
        ret = RESULT.INVALID
      } else if (typeof value === 'string' || Array.isArray(value)) {
        if (value.length < props.minLength) ret = RESULT.INVALID
      } else { throw new Error('minLength not supported') }
    }
    if (props.length > 0) {
      if (value === null || value === undefined) {
        ret = RESULT.INVALID
      } else if (typeof value === 'string' || Array.isArray(value)) {
        if (value.length > props.length) return RESULT.UNACCEPTED
        if (value.length < props.length) ret = RESULT.INVALID
      } else { throw new Error('length not supported') }
    }
    if (props.regex) {
      if (value === null || value === undefined) {
        ret = RESULT.INVALID
      } else if (typeof value === 'string') {
        if (!props.regex.test(value)) {
          ret = RESULT.INVALID
        }
      } else { throw new Error('regex not supported') }
    }
    if (props.number) {
      if (value === null || value === undefined || value === '') {
        ret = RESULT.INVALID
      } else if (!/^(0|[1-9][0-9]*)$/.test(value)) {
        return RESULT.UNACCEPTED
      }
    }
    if (props.money) {
      console.log('money', value)
      if (value === null || value === undefined || value === '') {
        ret = RESULT.INVALID
        console.log('money invalid')
      } else if (!/^-?(0|[1-9][0-9]*)(\.([0-9]{1,2}))?$/.test(value)) {
        if (/^-?((0|[1-9][0-9]*)(\.)?)?$/.test(value)) {
          ret = RESULT.INVALID
          console.log('money invalid2')
        } else {
          console.log('money unaccepted')
          return RESULT.UNACCEPTED
        }
      }
    }
    if (props.minValue) {
      if (value === null || value === undefined) {
        ret = RESULT.INVALID
      } else {
        const intValue = parseInt(value, 10)
        if (intValue < props.minValue) {
          ret = RESULT.INVALID
        }
      }
    }
    if (props.maxValue) {
      if (value === null || value === undefined) {
        ret = RESULT.INVALID
      } else {
        const intValue = parseInt(value, 10)
        if (intValue > props.maxValue) {
          return RESULT.UNACCEPTED
        }
      }
    }
    if (props.before) {
      if (value === null || value === undefined || !moment.isMoment(value)) {
        ret = RESULT.INVALID
      } else if (value.isAfter(moment(props.before))) {
        ret = RESULT.INVALID
      }
    }
    if (props.after) {
      if (value === null || value === undefined || !moment.isMoment(value)) {
        ret = RESULT.INVALID
      } else if (value.isBefore(moment(props.after))) {
        ret = RESULT.INVALID
      }
    }
    if (props.future) {
      if (value === null || value === undefined || !moment.isMoment(value)) {
        ret = RESULT.INVALID
      } else if (value.isBefore(moment())) {
        ret = RESULT.INVALID
      }
    }
    if (props.past) {
      if (value === null || value === undefined || !moment.isMoment(value)) {
        ret = RESULT.INVALID
      } else if (value.isAfter(moment())) {
        ret = RESULT.INVALID
      }
    }
    if (props.equalTo) {
      if (value !== props.equalTo) {
        ret = RESULT.INVALID
      }
    }
    if (props.notEqualTo) {
      if (value === props.notEqualTo) {
        ret = RESULT.INVALID
      }
    }
    if (props.inValue) {}
    if (props.email) {
      if (value === null || value === undefined || value === '') {
        ret = RESULT.INVALID
      } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
        return RESULT.INVALID
      }
    }
    if (props.phone) {
      if (value === null || value === undefined || value === '') {
        ret = RESULT.INVALID
      } else if (!/^[789]\d{0,7}$/.test(value)) {
        return RESULT.UNACCEPTED
      } else if (!/^[789]\d{7}$/.test(value)) {
        return RESULT.INVALID
      }
    }
    if (props.url) {
      if (value === null || value === undefined || value === '') {
        ret = RESULT.INVALID
        // TODO improve regex for url
      } else if (!/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(value)) {
        return RESULT.INVALID
      }
    }
    return ret
  }
}

Validator.RESULT = RESULT

export default Validator
