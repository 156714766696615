import React from 'react'
import strings from '../../locale/strings'

class ErrorPage extends React.Component {
  
  // componentDidMount () {
  //   window.scrollTo(0, 0)
  //   document.body.scrollTop = 0
  // }

  render () {
    return (
      <div
        className="uk-section section-hero uk-position-relative"
        data-uk-scrollspy="cls: uk-animation-slide-bottom-medium; repeat: true"
      >
        <div className="uk-container uk-container-small">
          <p className="hero-image uk-text-center">
            <img src="img/head_img.svg" alt="Hero" />
          </p>
          <h1 className="uk-heading-medium uk-text-center uk-margin-remove-top">
            404
          </h1>
          <p className="uk-text-lead uk-text-center">
          {strings.notFound}
          </p>
        </div>
      </div>
    )
  }
}

export default ErrorPage
