import React, { Component } from "react";

import Banner from "../../components/Banner.jsx"
import TripleChoice from "../../components/TripleChoice.jsx"
import KnowledgeBase from "../../components/KnowledgeBase.jsx"
import VideoIntro from "../../components/VideoIntro.jsx"
import Faq from "../../components/Faq.jsx"
import Security from "../../components/Security.jsx"
import Subscribe from "../../components/Subscribe.jsx"


class HomePage extends Component {
  render() {
    return (
        <div className="HomePage"> 
            <Banner/>
            <TripleChoice/>
            <KnowledgeBase/>
            <VideoIntro/>
            <Faq/>
            <Security/>
            <Subscribe/>
        </div>
    )
  }
}

export default HomePage
