import React from 'react'

export default React.createContext({
  locale: 'mn',
  token: null,
  showNotification: (text, color) => {
    console.log('showNotification not implemented', text)
  },
  handleError: (error) => {
    console.error(error)
  },
  toggleLocale: () => {
    console.log('toggleLocale not implemented')
  }
})

export const DataProvider = React.createContext({

})
