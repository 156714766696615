import React from "react";
import strings from "../locale/strings"

class Faq extends React.Component {
  render() {
    return (
      <div className="uk-section">
        <div className="uk-container uk-container-small">
          <h2 className="uk-h1 uk-text-center">{strings.faq}</h2>
          <p className="uk-text-center uk-text-lead">
            {strings.faqDesctiption}
          </p>
          <ul
            className="uk-margin-medium-top"
            data-uk-accordion="multiple: true"
          >
            <li>
              <a
                className="uk-accordion-title uk-box-shadow-hover-small"
                href="/"
              >
                {strings.faq1question}
              </a>
              <div className="article-content uk-accordion-content link-primary">
                <p>
                {strings.faq1answer}
                </p>
              </div>
            </li>
            <li>
              <a
                className="uk-accordion-title uk-box-shadow-hover-small"
                href="/"
              >
                {strings.faq2question}
              </a>
              <div className="article-content uk-accordion-content link-primary">
                <p>
                {strings.faq2answer}
                </p>
                <img src={strings.faqHowUrl} alt=""/>
              </div>
            </li>
            <li>
              <a
                className="uk-accordion-title uk-box-shadow-hover-small"
                href="/"
              >
                {strings.faq3question}
              </a>
              <div className="article-content uk-accordion-content link-primary">
                <p>
                {strings.faq3answer}
                </p>
              </div>
            </li>
            <li>
              <a
                className="uk-accordion-title uk-box-shadow-hover-small"
                href="/"
              >
                {strings.faq4question}
              </a>
              <div className="article-content uk-accordion-content link-primary">
                <p>
                {strings.faq4answer}
                </p>
              </div>
            </li>
            <li>
              <a
                className="uk-accordion-title uk-box-shadow-hover-small"
                href="/"
              >
                {strings.faq5question}
              </a>
              <div className="article-content uk-accordion-content link-primary">
                <p>
                {strings.faq5answer}
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Faq;
