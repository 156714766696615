import React from "react";
import strings from "../locale/strings"

class FooterLinks extends React.Component {
  render() {
    return (
      <ul className="uk-subnav uk-flex-center">
        <li>
          <a href="/" className="footer-menu">{strings.home}</a>
        </li>
        <li>
          <a href="/content/news" className="footer-menu">{strings.news}</a>
        </li>
        <li>
          <a href="/contact" className="footer-menu">{strings.contactUs}</a>
        </li>
      </ul>
    );
  }
}

export default FooterLinks;
